import style from "./Faq.module.scss";
import {Container} from "../common/Container/Container";
import React, {memo, useState} from "react";
import {H2} from "../common/H2/H2";
import {items} from "./constants";
import {translate} from "../../utils/lang";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectLang} from "../../redux-toolkit-store/appSlice";
import Collapse from "@mui/material/Collapse";

export const Faq = () => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const lang = useAppSelector(selectLang);

    return (
        <Container classNameInner={style.faq}>
            <H2 text="FAQ"/>

            <div className={style.cards}
                 //data-aos="fade-up"
            >
                {
                    items.map(({title, text}, index) => (
                        <div key={index}
                             data-aos="flip-up"
                             className={style.item}
                             onClick={() => setCurrentIndex(index)}
                        >
                            <p className={style.title}>{translate(title, lang)}</p>

                            <Collapse in={currentIndex === index}>
                                <p className={style.text}>{translate(text, lang)}</p>
                            </Collapse>

                        </div>
                    ))
                }
            </div>
        </Container>
    )
}

export const FaqMemo = memo(Faq);