import style from "./Work.module.scss";
import {H2} from "../../common/H2/H2";
import clsx from "clsx";
import {Container} from "../../common/Container/Container";
import React, {memo} from "react";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectCategory, selectLang, selectThemeType, setCategory} from "../../../redux-toolkit-store/appSlice";
import {skipToken} from "@reduxjs/toolkit/query";
import {useGetPortfolioByIdQuery, useIncrementCountMutation} from "../../../api/portfolio.api";
import {Preloader} from "../../common/Preloader/Preloader";
import main from "../../../assets/png/work/main.png";
import desktop from "../../../assets/png/work/desktop.png";
import mobile from "../../../assets/png/work/mobile.png";
import {portfolioIcons} from "../../B1_PortfolioPage/PortfolioItems/portfolioIcons";
import {MonitorModel} from "../../common/Three/MonitorModel/MonitorModel";
import {HashLink} from "react-router-hash-link";

export const Work = () => {
    const themeType = useAppSelector(selectThemeType);
    const lang = useAppSelector(selectLang);
    const category = useAppSelector(selectCategory);
    const dispatch = useAppDispatch();

    const {id} = useParams<{ id: string }>();

    const {data, isFetching} = useGetPortfolioByIdQuery(id ?? skipToken);
    const [incrementCount, {isLoading}] = useIncrementCountMutation();

    //const [data, setData] = useState<null | IPortfolio>(null)
    // useEffect(() => {
    //     if (id) {
    //       setData(portfolioMock[id])
    //     }
    // }, [id])
    //const isFetching = false;


    const onLinkHandler = () => {
        if (id) {
            incrementCount({id});
        }
    }

    return (
        <Container classNameInner={style.work}>

            {isFetching && <Preloader/>}

            {
                data &&
                <>
                    <div className={style.navigateLinks}>
                        <HashLink to={`/portfolio`}
                                  className={clsx({
                                      [style.link]: true,
                                      [style.link_light]: themeType === "light",
                                      [style.link_dark]: themeType === "dark",
                                  })}
                                  onClick={() => dispatch(setCategory("All"))}
                        >
                            All portfolios
                        </HashLink>

                        {
                            category !== "All" && (
                                <span className={clsx({
                                    [style.link]: true,
                                    [style.link_light]: themeType === "light",
                                    [style.link_dark]: themeType === "dark",
                                })}> / </span>
                            )
                        }

                        {
                            category !== "All" && (
                                <HashLink to={`/portfolio`}
                                          className={clsx({
                                              [style.link]: true,
                                              [style.link_light]: themeType === "light",
                                              [style.link_dark]: themeType === "dark",
                                          })}
                                >
                                    {category}
                                </HashLink>
                            )
                        }
                    </div>

                    <H2 text={data.name} translated={false} className={style.title}/>

                    <div className={style.icons}>
                        {
                            data.icons.map((icon, index) => (
                                <img key={index}
                                    // @ts-ignore
                                     src={portfolioIcons[icon]}
                                     alt=""
                                     className={style.item}
                                     data-aos="zoom-in"
                                     data-aos-delay={`${200 * index}`}
                                />
                            ))
                        }
                    </div>

                    <div className={style.textWrapper}>
                        <p className={style.text} data-aos="fade-right">{data.description[lang]}</p>

                        <a href={data.url}
                           className={clsx({
                               [style.link]: true,
                               [style.link_light]: themeType === "light",
                               [style.link_dark]: themeType === "dark",
                           })}
                           //data-aos="fade-left"
                           target="_blank"
                           rel="nofollow noopener noreferrer"
                           onClick={onLinkHandler}
                        >
                            {data.name}
                        </a>

                    </div>

                    <div className={style.imgsBlock}>
                        <div className={style.mainWrapper} data-aos="fade-up">
                            <img src={main} alt="" className={style.mainMask}/>
                            <img src={data.imgs.main} alt="" className={style.main}/>
                        </div>

                        <div className={style.bottomWrapper}>
                            <div className={clsx({
                                [style.desktopWrapper]: true,
                                [style.desktopWrapper_noMobile]: !data.imgs.mobile,
                            })}
                                 data-aos="fade-right"
                            >
                                <img src={desktop} alt="" className={style.desktopMask}/>
                                <img src={data.imgs.desktop} alt="" className={style.desktop}/>
                            </div>
                            {
                                data.imgs.mobile &&
                                <div className={style.mobileWrapper} data-aos="fade-left">
                                    <img src={mobile} alt="" className={style.mobileMask}/>
                                    <img src={data.imgs.mobile} alt="" className={style.mobile}/>
                                </div>
                            }
                        </div>


                    </div>

                </>
            }

        </Container>
    )
}

export const WorkMemo = memo(Work);
