import {svgIcons} from "../../assets/svg/svgIcons";
import png0 from "../../assets/png/our partners/Logo Antoniuk.png"

export const slides = [
    {
        png: png0,
        href: "https://antoniukagency.com/",
    },
    {
        svg: svgIcons.our_partners_annaniks,
        href: "https://annaniks.com/",
    },
    {
        svg: svgIcons.our_partners_demianchuk,
        href: "https://demyanchukart.com",
    },
    {
        svg: svgIcons.our_partners_nextcode,
        href: "https://nextcode.app/",
    },
    // дубль
    {
        png: png0,
        href: "https://antoniukagency.com/",
    },
    {
        svg: svgIcons.our_partners_annaniks,
        href: "https://annaniks.com/",
    },
    {
        svg: svgIcons.our_partners_demianchuk,
        href: "https://demyanchukart.com",
    },
    {
        svg: svgIcons.our_partners_nextcode,
        href: "https://nextcode.app/",
    },

    // {
    //     svg: svgIcons.our_partners_0,
    //     href: "#",
    // },
    // {
    //     svg: svgIcons.our_partners_1,
    //     href: "#",
    // },
    // {
    //     svg: svgIcons.our_partners_2,
    //     href: "#",
    // },
    // {
    //     svg: svgIcons.our_partners_3,
    //     href: "#",
    // },
    // {
    //     svg: svgIcons.our_partners_4,
    //     href: "#",
    // },
];
