import React from "react";
import style from "./Preloader.module.scss"
import {CircularProgress} from "@mui/material";

export const Preloader = () => {
    const thickness = 1;

    return (
        <div className={style.preloader}>

            <div className={style.circularProgress}>
                <CircularProgress size={50}
                                  thickness={thickness}
                sx={{
                    color: 'rgba(25,118,210, 0.3)'
                }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={75}
                                  thickness={thickness}
                                  sx={{
                                      color: 'rgba(25,118,210, 0.3)'
                                  }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={100}
                                  thickness={thickness}
                                  sx={{
                                      color: 'rgba(25,118,210, 0.6)'
                                  }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={125}
                                  thickness={thickness}
                                  sx={{
                                      color: 'rgba(25,118,210, 0.6)'
                                  }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={150}
                                  thickness={thickness}
                                  sx={{
                                      color: 'rgba(25,118,210, 0.6)'
                                  }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={175}
                                  thickness={thickness}
                                  sx={{
                                      color: 'rgba(25,118,210, 0.6)'
                                  }}
                />
            </div>

            <div className={style.circularProgress}>
                <CircularProgress size={200}
                                  thickness={thickness}
                />
            </div>
        </div>
    )
}