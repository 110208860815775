import React, {FC} from "react";
import clsx from "clsx";
import {IPortfolio} from "../../../types/portfolio.types";
import {useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectThemeType} from "../../../redux-toolkit-store/appSlice";
import style from "./Pagination.module.scss"

interface IPagination {
    items: IPortfolio[]
    currentPage: number
    onChange: (page: number) => void
}

export const Pagination: FC<IPagination> = ({
                                                items,
                                                currentPage,
                                                onChange
                                            }) => {
    const themeType = useAppSelector(selectThemeType);

    return (
        <div className={style.pagination}>
            {
                Array.from({length: Math.ceil(items.length / 5)}, (v, k) => k + 1).map(page => (
                    <button key={page}
                            className={clsx({
                                [style.paginationButton]: true,
                                [style.paginationButton_light]: themeType === "light",
                                [style.paginationButton_dark]: themeType === "dark",
                                [style.paginationButton_selected_light]: themeType === "light" && page === currentPage,
                                [style.paginationButton_selected_dark]: themeType === "dark" && page === currentPage,
                            })}
                            onClick={() => onChange(page)}
                    >
                        <p>{page}</p>
                    </button>
                ))
            }

        </div>
    )
}
