import React, {FC} from "react";
import style from "./links.module.scss"
import clsx from "clsx";
import { HashLink } from 'react-router-hash-link';
import {translate} from "../../../utils/lang";
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectLang, selectThemeType, setCategory} from "../../../redux-toolkit-store/appSlice";

export const links = [
    {to: "/#about", label: "about us"},
    {to: "/portfolio/#header", label: "portfolio"},
    // {to: "/shop/#header", label: "shop"},
    {to: "#contacts", label: "contacts"},
];

interface ILinks {
    className?: string
}

export const Links: FC<ILinks> = ({className}) => {
    const themeType = useAppSelector(selectThemeType);
    const lang = useAppSelector(selectLang);

    const dispatch = useAppDispatch();

    const location = useLocation();
    const pathname = location.pathname;

    //links[3].to = pathname + "/#contacts";
    links[2].to = pathname + "#contacts";

    //console.log(pathname)

    return (
        <nav className={clsx({
            [style.links]: true,
            [style.links_light]: themeType === "light",
            [style.links_dark]: themeType === "dark",
        }, className)}
             //data-aos="fade-left"
        >

            {/*<Link to={links[1].to}*/}
            {/*      className={clsx({*/}
            {/*          [style.link]: true,*/}
            {/*          [style.link_light]: themeType === "light",*/}
            {/*          [style.link_dark]: themeType === "dark",*/}
            {/*      })}*/}
            {/*>*/}
            {/*    {translate(links[1].label, lang)}*/}
            {/*</Link>*/}

            {
                links.map((link, index) => (
                    <HashLink key={index}
                              to={link.to}
                              className={clsx({
                                  [style.link]: true,
                                  [style.link_light]: themeType === "light",
                                  [style.link_dark]: themeType === "dark",
                              })}
                              smooth={true}
                              onClick={() => dispatch(setCategory("All"))}
                    >
                        {
                            translate(link.label, lang)
                        }
                    </HashLink>
                ))
            }
        </nav>
    )
}
