import {useField} from "formik";
import React, {FC} from "react";
import {SelectProps} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import FormHelperText from "@mui/material/FormHelperText";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import clsx from "clsx";
// import design_ai from "../../../assets/png/offer/card-icons/0.png";
// import design_ps from "../../../assets/png/offer/card-icons/1.png";
// import design_figma from "../../../assets/png/offer/card-icons/2.png";
// import design_uiux from "../../../assets/png/offer/card-icons/3.png";
// import frontend_react from "../../../assets/png/offer/card-icons/4.png";
// import frontend_redux from "../../../assets/png/offer/card-icons/frontend_redux.png";
// import frontend_nextjs from "../../../assets/png/offer/card-icons/frontend_nextjs.png";
// import frontend_material_ui from "../../../assets/png/offer/card-icons/frontend_materialui.png";
// import frontend_sass from "../../../assets/png/offer/card-icons/frontend_sass.png";
// import frontend_formik from "../../../assets/png/offer/card-icons/frontend_formik.png";
// import frontend_ts from "../../../assets/png/offer/card-icons/frontend_typescript.png";
// import frontend_saga from "../../../assets/png/offer/card-icons/frontend_saga.png";
// import frontend_router from "../../../assets/png/offer/card-icons/frontend_react-router.png";
// import frontend_axios from "../../../assets/png/offer/card-icons/frontend_axios.png";
// import backend_nodejs from "../../../assets/png/offer/card-icons/5.png";
// import crypto_ethereum from "../../../assets/png/offer/card-icons/10.png";
import {portfolioIcons} from "../../B1_PortfolioPage/PortfolioItems/portfolioIcons";
import style from "./SelectIcons.module.scss";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export interface IMenuItem {
    value: string
    label: string
}

type FieldMultipleSelectType = {
    //name: string
    //menuItems: Array<IMenuItem>
    className?: string
} & SelectProps;

export const SelectIcons: FC<FieldMultipleSelectType> = ({
                                                             //name,
                                                             //menuItems,
                                                             className,
                                                             ...props
                                                         }) => {
    const name = "icons";
    const menuItems = [
        "design_ai",
        "design_ps",
        "design_figma",
        "design_uiux",
        "frontend_react",
        "frontend_redux",
        "frontend_nextjs",
        "frontend_material_ui",
        "frontend_sass",
        "frontend_formik",
        "frontend_ts",
        "frontend_saga",
        "frontend_router",
        "frontend_axios",
        "backend_nodejs",
        "crypto_ethereum"
    ].map(item => ({value: item, label: item}))

    const [field, meta, helpers] = useField(name);

    return (
        <div className={clsx(Boolean(className) && className)}>
            <FormControl variant='outlined'
                         fullWidth={true}
                         size='small'
                         error={meta.touched && Boolean(meta.error)}
            >
                <InputLabel id={name}>{props.label}</InputLabel>
                <Select
                    name={field.name}
                    label={props.label}
                    multiple
                    value={field.value}
                    onBlur={field.onBlur}
                    onChange={field.onChange}
                    error={meta.touched && Boolean(meta.error)}
                    MenuProps={MenuProps}
                    renderValue={(selected: string[]) => (
                        <div className={style.renderValue}>
                            {
                                selected.map(selectedItem => (
                                    // @ts-ignore
                                    <img src={portfolioIcons[selectedItem]}
                                         alt=""
                                         className={style.menuItemImg}
                                         key={selectedItem}
                                    />
                                ))
                            }
                        </div>

                    )}
                >
                    {
                        menuItems
                            .map(el => (
                                <MenuItem key={el.value} value={el.value}>
                                    <Checkbox checked={field.value.indexOf(el.value) > -1}/>
                                    {/*@ts-ignore*/}
                                    <img src={portfolioIcons[el.label]} alt="" className={style.menuItemImg}/>
                                    <ListItemText primary={el.label}/>
                                </MenuItem>
                            ))
                    }
                </Select>
                <FormHelperText>
                    {meta.touched && meta.error}
                </FormHelperText>
            </FormControl>
        </div>
    )
};
