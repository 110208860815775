import React, {FC, useState} from "react";
import {FieldsetCustom} from "../../x_form/FieldsetCustom/FieldsetCustom";
import style from './ItemForm.module.scss';
import {Form, Formik, FormikErrors, FormikHelpers, FormikProps} from "formik";
import {FieldText} from "../../x_form/FieldText/FieldText";
import {IPortfolioFormValues} from "../../../types/portfolio.types";
import {FormControlLabel} from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import {FieldLoadImage} from "../../x_form/FieldLoadImage/FieldLoadImage";
import {FieldMultipleSelect} from "../../x_form/FieldMultipleSelect/FieldMultipleSelect";
import Button from "@mui/material/Button";
import {SelectIcons} from "../../x_form/SelectIcons/SelectIcons";

interface IItemForm {
    label: string
    initialValues: IPortfolioFormValues
    onSubmitHandler: (portfolioUpdateData: IPortfolioFormValues) => Promise<void>
}

export const ItemForm: FC<IItemForm> = ({label, initialValues, onSubmitHandler}) => {

    const onSubmit = async (values: IPortfolioFormValues, formikHelpers: FormikHelpers<IPortfolioFormValues>) => {
        try {
            console.log(values)
            await onSubmitHandler(values);
        } finally {
            formikHelpers.setSubmitting(false);
            formikHelpers.resetForm();
        }
    };

    const [enable, setEnable] = useState(initialValues.enable);
    const onChangeHandler = (event: any) => {
        setEnable(event.target.checked);
    };
    const menuItems = ["Frontend", "Mobile", "Games", "Advertising", "Blockchain", "Backend"]
        .map(text => ({value: text, label: text}))

    const validate = (values: IPortfolioFormValues): FormikErrors<IPortfolioFormValues> => {
        const errors = {} as FormikErrors<IPortfolioFormValues>
        if (!values.name) {
            errors.name = "Заполните поле"
        }
        if (!values.url) {
            errors.url = "Заполните поле"
        }
        if (!values.descriptionEng) {
            errors.descriptionEng = "Заполните поле"
        }
        if (!values.descriptionRus) {
            errors.descriptionRus = "Заполните поле"
        }
        if (!values.category.length) {
            errors.category = "Заполните поле"
        }
        if (!values.imgsMain) {
            errors.imgsMain = "Выберите изображение"
        }
        if (!values.imgsDesktop) {
            errors.imgsDesktop = "Выберите изображение"
        }
        // if (!values.imgsMobile) {
        //     errors.imgsMobile = "Выберите изображение"
        // }
        return errors
    }

    return (
        <Formik initialValues={initialValues}
                onSubmit={onSubmit}
                validate={validate}
                enableReinitialize={true}
        >
            {
                (props: FormikProps<IPortfolioFormValues>) => (
                    <FieldsetCustom label={label}
                                    className={style.itemForm}
                    >
                        <Form className={style.form}>
                            <FieldText name="name"
                                       label="Название"
                                       size="small"
                                       placeholder="Введите название"
                                       className={style.field}
                                       fullWidth
                            />
                            <FieldText name="url"
                                       label="URL"
                                       size="small"
                                       placeholder="Введите ссылку"
                                       className={style.field}
                                       fullWidth
                            />
                            <FieldText name="descriptionEng"
                                       label="Описание (ENG)"
                                       size="small"
                                       placeholder="Введите описание (ENG)"
                                       className={style.field}
                                       fullWidth
                                       multiline={true}
                                       minRows={2}

                            />
                            <FieldText name="descriptionRus"
                                       label="Описание (RUS)"
                                       size="small"
                                       placeholder="Введите описание (RUS)"
                                       className={style.field}
                                       fullWidth
                                       multiline={true}
                                       minRows={2}
                            />

                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={enable}/>}
                                                  label={enable ? "Активно" : "Не активно"}
                                                  onChange={onChangeHandler}
                                />
                            </FormGroup>

                            <FieldsetCustom label="IMAGES" className={style.images} fullWidth>
                                <FieldLoadImage name={"imgsMain"}
                                                label={"IMAGE - MAIN"}
                                                description="Рекомендуемая ширина - 1440px"
                                />
                                <FieldLoadImage name={"imgsDesktop"}
                                                label={"IMAGE - DESKTOP"}
                                                className={style.field}
                                                description="Рекомендуемая ширина - 1440px"
                                />
                                <FieldLoadImage name={"imgsMobile"}
                                                label={"IMAGE - MOBILE"}
                                                className={style.field}
                                                description="Рекомендуемая ширина - 320px...360px"
                                />
                            </FieldsetCustom>

                            <FieldMultipleSelect name="category"
                                                 label="Категория"
                                                 menuItems={menuItems}
                                                 className={style.field}
                            />

                            <SelectIcons className={style.field} label="Иконки технологий"/>

                            <Button type="submit"
                                    variant="contained"
                                    color="success"
                                    fullWidth
                                    className={style.field}
                            >
                                Сохранить
                            </Button>

                        </Form>
                    </FieldsetCustom>
                )
            }
        </Formik>


    )
}
