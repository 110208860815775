import {Canvas, useFrame, useThree} from "@react-three/fiber";
import React, {FC, useRef} from "react";
import {Box, Instance, Instances, OrbitControls, PerspectiveCamera, Stats} from "@react-three/drei";
import * as THREE from "three";
import {getBaseGeometry, getMaskGeometry, getScreenGeometry, MobileModel} from "./MobileModel";
import {TabletModel} from "./TabletModel";

import mobile_0 from "../../../../assets/png/First Block Model/mobile/0.jpg";
import mobile_1 from "../../../../assets/png/First Block Model/mobile/1.jpg";
import mobile_2 from "../../../../assets/png/First Block Model/mobile/2.jpg";
import mobile_3 from "../../../../assets/png/First Block Model/mobile/3.jpg";
import mobile_4 from "../../../../assets/png/First Block Model/mobile/4.jpg";
import mobile_5 from "../../../../assets/png/First Block Model/mobile/5.jpg";

import tablet_0 from "../../../../assets/png/First Block Model/tablet/0.jpg";
import tablet_1 from "../../../../assets/png/First Block Model/tablet/1.jpg";
import tablet_2 from "../../../../assets/png/First Block Model/tablet/2.jpg";
import tablet_3 from "../../../../assets/png/First Block Model/tablet/3.jpg";
import tablet_4 from "../../../../assets/png/First Block Model/tablet/4.jpg";
import tablet_5 from "../../../../assets/png/First Block Model/tablet/5.jpg";
import {makeArray} from "../../../../utils/makeArray";
import {Button} from "@mui/material";

const mobileSrcs = [
    mobile_0,
    mobile_1,
    mobile_2,
    mobile_3,
    mobile_4,
    mobile_5,
];

const tabletSrcs = [
    tablet_0,
    tablet_1,
    tablet_2,
    tablet_3,
    tablet_4,
    tablet_5,
];

const params = {
    cameraFactor: 1.8,
    mobileSceneRadius: 4,
    mobileSceneHeight: 1,
    mobileHalhAngle: 6,
    tabletSceneRadius: 3.45,
    tabletSceneHeight: 1,
    tabletHalhAngle: 3,
    rotation: 0.0006,
    count: 6,
    halhAngle: 5,
    tabletScale: 1.8,
}

const mobileParams = {
    factor: 2,
    screenOffset: 0.05,
    screenRadius: 0.1,
    screenBevel: 0.02,
    screenDepth: 0.05,
    maskOffset: 0.01,
    baseOffset: 0.01,
    baseBevel: 0.04,
    screenColor: "#222",
    baseColor: "#333",
}

//========= FIRST BLOCK =========//
export const FirstBlock = () => {

    // const ref = useRef<HTMLCanvasElement>(null!);
    //
    // const onSave = () => {
    //     const canvas = ref.current;
    //
    //     const image = canvas.toDataURL("image/png");
    //     const a = document.createElement("a");
    //     a.href = image.replace(/^data:image\/[^;]/, 'data:application/octet-stream');
    //     a.download="image.png"
    //     a.click();
    // }

    return (
        // <div style={{
        //     width: "100%",
        //     height: "100%",
        //     position: "relative"
        // }}
        // >
        //     <Button variant="contained"
        //             style={{
        //                 position: "absolute",
        //                 zIndex: 100,
        //             }}
        //             onClick={onSave}
        //     >
        //         Save image
        //     </Button>

            <Canvas gl={{
                antialias: true,
                //preserveDrawingBuffer: true
            }}
                    // style={{
                    //     width: "100%",
                    //     height: "100%",
                    //     zIndex: 50,
                    // }}
                    // ref={ref}

            >

                <ambientLight intensity={1}/>
                <directionalLight position={[2, 0, 2]} intensity={1.0}/>
                <directionalLight position={[-2, 0, 2]} intensity={1.0}/>

                <FirstBlockModel/>

                {process.env.NODE_ENV === 'development' && <Stats/>}

                <PerspectiveCamera makeDefault
                                   position={[0 * params.cameraFactor, 1.5 * params.cameraFactor, 6 * params.cameraFactor]}
                                   rotation={new THREE.Euler(0, 0, 0)}
                                   zoom={1.4}
                />

                <OrbitControls enableRotate={false}
                               enableZoom={false}
                />

                {/*<axesHelper/>*/}

            </Canvas>

        //</div>

    )
}

//========= FIRST BLOCK MODEL =========//
const FirstBlockModel = () => {
    const ref = useRef<THREE.Group>(null!);

    useFrame((state, delta) => {
        ref.current.rotation.y -= params.rotation;
    })

    return (
        <group ref={ref}
               position-y={0.32}
               rotation-y={Math.PI/2}
        >

            {/*mobile - window mask*/}
            {/*<Instances limit={params.count}*/}
            {/*           range={params.count}*/}
            {/*           geometry={getMaskGeometry()}*/}
            {/*>*/}
            {/*    <MobileScreenMaterial/>*/}
            {/*    {*/}
            {/*        makeArray(params.count).map(index => (*/}
            {/*                <MobileWrapper key={index}*/}
            {/*                               index={index}*/}
            {/*                >*/}
            {/*                    <group*/}
            {/*                        position={[0, mobileParams.screenOffset, mobileParams.screenDepth + mobileParams.screenBevel + 0.0011]}*/}
            {/*                    >*/}
            {/*                        <Instance/>*/}
            {/*                    </group>*/}
            {/*                </MobileWrapper>*/}
            {/*            )*/}
            {/*        )*/}
            {/*    }*/}
            {/*</Instances>*/}

            {/*mobile - screen*/}
            {/*<Instances limit={params.count}*/}
            {/*           range={params.count}*/}
            {/*           geometry={getScreenGeometry()}*/}
            {/*>*/}
            {/*    <MobileScreenMaterial/>*/}
            {/*    {*/}
            {/*        makeArray(params.count).map(index => (*/}
            {/*                <MobileWrapper key={index}*/}
            {/*                               index={index}*/}
            {/*                >*/}
            {/*                    <Instance/>*/}
            {/*                </MobileWrapper>*/}
            {/*            )*/}
            {/*        )*/}
            {/*    }*/}
            {/*</Instances>*/}

            {/*mobile - base*/}
            {/*<Instances limit={params.count}*/}
            {/*           range={params.count}*/}
            {/*           geometry={getBaseGeometry()}*/}
            {/*>*/}
            {/*    <MobileBaseMaterial/>*/}
            {/*    {*/}
            {/*        makeArray(params.count).map(index => (*/}
            {/*                <MobileWrapper key={index}*/}
            {/*                               index={index}*/}
            {/*                >*/}
            {/*                    <group position={[0, 0, -2 * mobileParams.screenBevel]}>*/}
            {/*                        <Instance/>*/}
            {/*                    </group>*/}
            {/*                </MobileWrapper>*/}
            {/*            )*/}
            {/*        )*/}
            {/*    }*/}
            {/*</Instances>*/}


            {/*mobile*/}
            <group position={[0, 0.05 * params.mobileSceneHeight, 0]}>
                {
                    makeArray(params.count).map(index => (
                            <MobileWrapper key={index}
                                           index={index}
                            >
                                <MobileModel src={index > 5 ? mobileSrcs[0]: mobileSrcs[index]}/>
                            </MobileWrapper>
                        )
                    )
                }
            </group>

            {/*tablet*/}
            <group position={[0, -0.0 * params.tabletSceneHeight, 0]}>
                {
                    Array
                        .from({length: params.count}, (_, key) => key)
                        .map(index => (
                                <group key={index}
                                       rotation-y={(index + 0.5) * (2 * Math.PI / params.count)}
                                >
                                    <group position={[
                                        0,
                                        index % 2 === 0 ? params.tabletSceneHeight : 0,
                                        params.tabletSceneRadius
                                    ]}
                                           rotation-z={Math.pow(-1, index + 1) * Math.PI * (-params.tabletHalhAngle / 180)}
                                    >
                                        <group scale={params.tabletScale}>
                                            <TabletModel src={index > 5 ? tabletSrcs[0]: tabletSrcs[index]}
                                            />
                                        </group>

                                    </group>
                                </group>
                            )
                        )
                }
            </group>

        </group>
    )
}

//========= MOBILE WRAPPER =========//
interface IMobileWrapper {
    index: number
    children: JSX.Element
}

const MobileWrapper: FC<IMobileWrapper> = ({index, children}) => {
    return (
        <group key={index}
               rotation-y={index * (2 * Math.PI / params.count)}
        >
            <group position={[
                0,
                index % 2 === 0 ? params.mobileSceneHeight : 0,
                params.mobileSceneRadius
            ]}
                   rotation-z={Math.pow(-1, index) * Math.PI * (-params.mobileHalhAngle / 180)}
            >
                {children}
            </group>
        </group>
    )
}
//========= MOBILE SCREEN MATERIAL =========//
const MobileScreenMaterial = () => {
    return (
        <meshStandardMaterial color={mobileParams.screenColor}
                              emissive={mobileParams.screenColor}
                              roughness={0.2}
                              metalness={1}
        />
    )
}

//========= MOBILE BASE MATERIAL =========//
const MobileBaseMaterial = () => {
    return (
        <meshStandardMaterial color={mobileParams.baseColor}
                              emissive={mobileParams.baseColor}
                              roughness={0.2}
                              metalness={1}
        />
    )
}



