import {Dds} from "../C0_DDS/Dds";
import React, {FC, useEffect} from "react";
import {OfferMemo} from "../C1_Offer/Offer";
import {HowMuchIsMemo} from "../C2_HowMuchIs/HowMuchIs";
import {AboutUsMemo} from "../C3_AboutUs/AboutUs";
import {Form} from "../C6_Form/Form";
//import {CustomerReviewsMemo} from "../C7_CustomerReviews/CustomerReviews";
import {OurPartnersMemo} from "../C8_OurPartners/OurPartners";
import {FaqMemo} from "../C9_Faq/FaqComponent";
import {BigForm} from "../common/BigForm/BigForm";
import style from "./HomePage.module.scss"
import wave_dark from "../../assets/png/wave_dark-min.png";
import wave_light from "../../assets/png/wave_light-min.png";
import {PortfolioMemo} from "../C5_Portfolio/Portfolio";
import "./wave.scss"
import clsx from "clsx";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectPreloader, selectThemeType, ThemeTypeEnum, setPreloader} from "../../redux-toolkit-store/appSlice";
import {Preloader} from "../A5_Preloader/Preloader";
//import {OurTeamMemo} from "../C4_OurTeam/OurTeam";

export const HomePage = () => {
    const themeType = useAppSelector(selectThemeType);
    const preloader = useAppSelector(selectPreloader);

    const dispatch = useAppDispatch();

    const wave = themeType === ThemeTypeEnum.light ? wave_light : wave_dark;


    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(setPreloader(false));
        }, 3000)
        return () => clearTimeout(timer);
    }, [])

    return (
        <div className={style.homePage}>

            { preloader && <Preloader/> }

            <div className={style.images}>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
            </div>

            <div className={style.content}>
                <Dds/>
                <OfferMemo/>
                <HowMuchIsMemo/>
                <AboutUsMemo/>
                {/*<OurTeamMemo/>*/}
                <PortfolioMemo title="Portfolio"/>
                <Form/>
                {/*<CustomerReviewsMemo/>*/}
                <OurPartnersMemo/>
                <FaqMemo/>
                <div id="contacts">
                    <BigForm title="Do you have any questions?"/>
                </div>
            </div>
        </div>
    )
}
