import React, {FC} from "react";
import style from "./BurgerButton.module.scss"
import clsx from "clsx";
import {svgIcons} from "../../../assets/svg/svgIcons";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectBurgerMenu, selectShowModal, setBurgerMenu} from "../../../redux-toolkit-store/appSlice";

interface IBurgerButton {
    className?: string
}

export const BurgerButton: FC<IBurgerButton> = ({className}) => {
    const showModal = useAppSelector(selectShowModal);
    const burgerMenu = useAppSelector(selectBurgerMenu);
    const dispatch = useAppDispatch();

    return (
        <div className={clsx({
            [style.burgerButton]: true,
            [style.burgerButton_modal]: showModal.show,
        }, className)}
             onClick={() => dispatch(setBurgerMenu(!burgerMenu))}
        >
            {/*{svgIcons.burger}*/}
            <div className={clsx({
                [style.burger]: true,
                [style.burger_burgerMenu]: burgerMenu,
            })}>
                <div className={style.burgerInner}/>
            </div>
        </div>
    )
}