import style from "./OurPartners.module.scss"
import {Container} from "../common/Container/Container";
import React, {memo, useLayoutEffect, useRef, useState} from "react";
import {H2} from "../common/H2/H2";
import SwiperClass from "swiper/types/swiper-class";
import "swiper/css";
// import {Swiper, SwiperSlide} from "swiper/react";
// import {Pagination} from "../common/Pagination/Pagination";
import clsx from "clsx";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectThemeType} from "../../redux-toolkit-store/appSlice";
import {slides} from "./const";
import gsap from "gsap";

export const OurPartners = () => {
    const [swiper, setSwiper] = useState<SwiperClass | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const themeType = useAppSelector(selectThemeType);

    const appRef = useRef<HTMLDivElement>(null!);
    const duration = 35;

    useLayoutEffect(() => {
        const ctx = gsap.context(() => {
            gsap.timeline({repeat: -1})
                .to(".row_1", {xPercent: -100, duration, ease: "none"})
                .set(".row_1", {xPercent: 100})
                .to(".row_1", {xPercent: 0, duration, ease: "none"});

            gsap.timeline({repeat: -1})
                .set(".row_2", {xPercent: 100})
                .to(".row_2", {xPercent: -100, duration: 2 * duration, ease: "none"})
                .set(".row_2", {xPercent: 100});
        }, appRef);
        return () => ctx.revert();
    }, [])

    return (
        <div className={style.ourPartners}
             ref={appRef}
        >
            <Container>
                <H2 text="Our partners"/>

                {/*<Swiper data-aos="fade-up"*/}
                {/*        slidesPerView="auto"*/}
                {/*        centeredSlides={true}*/}
                {/*        loop={true}*/}
                {/*        slidesPerGroup={1}*/}
                {/*        onSwiper={(swiper) => {*/}
                {/*            setSwiper(swiper);*/}
                {/*        }}*/}
                {/*        onSlideChange={swiper => setCurrentIndex(swiper.realIndex)}*/}
                {/*        className={style.swiper}*/}
                {/*    //spaceBetween={50}*/}
                {/*>*/}
                {/*    {*/}
                {/*        slides.map(({svg, href, png}, index) => (*/}
                {/*            <SwiperSlide key={index}*/}
                {/*                         className={clsx({*/}
                {/*                             [style.slide]: true,*/}
                {/*                             //[style.slide_selected_light]: index === currentIndex && themeType === "light",*/}
                {/*                             //[style.slide_selected_dark]: index === currentIndex && themeType === "dark",*/}
                {/*                         })}*/}
                {/*            >*/}
                {/*                <a href={href}*/}
                {/*                   target="_blank"*/}
                {/*                   rel="noopener nofollow noreferrer"*/}
                {/*                >*/}
                {/*                    {*/}
                {/*                        svg ? <span>{svg}</span> : <img src={png} alt=""/>*/}
                {/*                    }*/}

                {/*                </a>*/}

                {/*            </SwiperSlide>*/}
                {/*        ))*/}
                {/*    }*/}
                {/*</Swiper>*/}

                {/*<Pagination dotsNumber={slides.length}*/}
                {/*            currentIndex={currentIndex}*/}
                {/*            onClick={(index: number) => swiper?.slideTo(index)}*/}
                {/*            className={style.pagination}*/}
                {/*/>    */}

            </Container>

            <div className={style.wrapper}
                 //data-aos="fade-up"
            >
                <div className={clsx(style.row, "row_1")}>
                    {
                        slides.map(({svg, href, png}, index) => (
                            <div key={index}
                                 className={clsx({
                                     [style.slide]: true,
                                     //[style.slide_selected_light]: index === currentIndex && themeType === "light",
                                     //[style.slide_selected_dark]: index === currentIndex && themeType === "dark",
                                 })}
                            >
                                <a href={href}
                                   target="_blank"
                                   rel="noopener nofollow noreferrer"
                                >
                                    {svg ? <span>{svg}</span> : <img src={png} alt=""/>}
                                </a>
                            </div>
                        ))
                    }

                </div>

                <div className={clsx(style.row, "row_2")}>
                    {
                        slides.map(({svg, href, png}, index) => (
                            <div key={index}
                                 className={clsx({
                                     [style.slide]: true,
                                     //[style.slide_selected_light]: index === currentIndex && themeType === "light",
                                     //[style.slide_selected_dark]: index === currentIndex && themeType === "dark",
                                 })}
                            >
                                <a href={href}
                                   target="_blank"
                                   rel="noopener nofollow noreferrer"
                                >
                                    {svg ? <span>{svg}</span> : <img src={png} alt=""/>}
                                </a>
                            </div>
                        ))
                    }

                </div>
            </div>

        </div>
    )
}

export const OurPartnersMemo = memo(OurPartners)
