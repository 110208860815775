import {configureStore} from '@reduxjs/toolkit'
import { appReducer } from './appSlice';
import {portfolioApi} from "../api/portfolio.api";
import {authReducer} from "./authSlice";

const isProduction = process.env.NODE_ENV === 'production';

export const store = configureStore({
    reducer: {
        [portfolioApi.reducerPath]: portfolioApi.reducer,
        app: appReducer,
        auth: authReducer
    },
    middleware: (getDefaultMiddleware) => isProduction
        ? getDefaultMiddleware()
        : getDefaultMiddleware().concat(
            portfolioApi.middleware,
        ),
    devTools: !isProduction,
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

