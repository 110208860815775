import React, {FC} from "react";
import style from "./PortfolioItem.module.scss";
import {IPortfolio} from "../../../types/portfolio.types";
import {LangEnum, ThemeTypeEnum} from "../../../redux-toolkit-store/appSlice";
import {HashLink} from "react-router-hash-link";
import clsx from "clsx";
import {svgIcons} from "../../../assets/svg/svgIcons";
import {MonitorModel} from "../../common/Three/MonitorModel/MonitorModel";
import {TabletModel} from "../../common/Three/TabletModel/TabletModel";
import {MobileModel} from "../../common/Three/MobileModel/MobileModel";

interface IPortfolioItemComponent {
    item: IPortfolio,
    index: number
    themeType: ThemeTypeEnum
    lang: LangEnum
}

export const PortfolioItem: FC<IPortfolioItemComponent> = ({
                                                               item: {
                                                                   id,
                                                                   imgs,
                                                                   name,
                                                                   description,
                                                                   viewCount
                                                               },
                                                               index,
                                                               themeType,
                                                               lang

                                                           }) => {
    return (
        <div className={style.portfolioItem}>

            <HashLink to={`/portfolio/${id}/#header`}
                      className={style.imgWrapper}
            >
                <div className={style.monitor}>
                    <MonitorModel src={imgs.main} isLeft={index % 2 === 0}/>
                </div>

                <img src={imgs.main} alt=""/>

            </HashLink>

            <div className={style.textBlock}>

                <HashLink to={`/portfolio/${id}/#header`}
                          className={style.title}
                >
                    {name}
                </HashLink>

                <p className={style.text}>{description[lang]}</p>

                <div className={clsx({
                    [style.countWrapper]: true,
                    [style.countWrapper_light]: themeType === "light",
                    [style.countWrapper_dark]: themeType === "dark",
                })}>
                    {svgIcons.view}
                    <p className={style.count}>{viewCount}</p>
                </div>


            </div>

        </div>
    )
}
