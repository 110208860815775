import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectAlert, selectAlertText, setAlert, setAlertText} from "../../../redux-toolkit-store/appSlice";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const AlertCustom = () => {
   const alert = useAppSelector(selectAlert);
   const alertText = useAppSelector(selectAlertText);
   const dispatch = useAppDispatch();

   const onCloseHandler = (event?: React.SyntheticEvent | Event, reason?: string) => {
       if (reason === 'clickaway') {
           return;
       }
       dispatch(setAlert(false));
       dispatch(setAlertText(""));
   };

    return (
       <Snackbar open={alert} autoHideDuration={6000} onClose={onCloseHandler}>
           <Alert onClose={onCloseHandler} severity="error" sx={{ width: '100%' }}>
               {alertText}
           </Alert>
       </Snackbar>
   )
}