import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/dist/query/react";
import {baseURL} from "./api";
import {
    IPortfolio,
    IPortfolioUpdate,
    IPortfolioItem,
    ChangeItemsOrderType
} from "../types/portfolio.types";
import {getLocalStorage} from "../localStorage/localStorage";

const mutationApis = [
    "incrementCount",
    "updateEnable",
    "updatePortfolio",
    "createPortfolio",
    "deletePortfolio",
    "changeItemsOrder",
];

export const portfolioApi = createApi({
    reducerPath: "portfolioApi",
    baseQuery: fetchBaseQuery({
        baseUrl: baseURL,
        credentials: "include",
        prepareHeaders: (headers, api) => {
            // console.log(headers)
            // console.log(api)
            if (mutationApis.includes(api.endpoint)) {
                const accessToken = getLocalStorage() || '';
                headers.set('x-access-token', accessToken);
            }
            return headers
        }
    }),
    tagTypes: ["Portfolio", "Portfolios", "Portfolios list"],
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
    refetchOnReconnect: true,
    endpoints: (builder) => ({
        //========= GET LIST =========//
        getPortfoliosList: builder.query<IPortfolioItem[], void | null>({
            query: () => "/portfolio/list",
            providesTags: ["Portfolios list"],
        }),
        //========= GET ALL =========//
        getAllPortfolios: builder.query<IPortfolio[], void | null>({
            query: () => "/portfolio",
            providesTags: ["Portfolios"],
        }),
        //========= GET BY ID =========//
        getPortfolioById: builder.query<IPortfolio, string>({
            query: (id) => `/portfolio/id/${id}`,
            providesTags: (result, error, id) => [{type: "Portfolio", id}],
        }),
        //============================ < Тип возвращаемого результата, Тип body - то что передается параметром в триггер мутации>
        //========= VIEW COUNT =========//
        incrementCount: builder.mutation<string, { id: string }>({
            query: ({id}) => ({
                url: `/portfolio/count/${id}`,
                method: "PUT",
                body: id,
            }),
            invalidatesTags: (result, error, arg) => [{type: "Portfolio", id: arg.id}, "Portfolios"],
        }),
        //========= ENABLE =========//
        updateEnable: builder.mutation<string, { enable: boolean, id: string }>({
            query: ({enable, id}) => ({
                url: `/portfolio/list/${id}`,
                method: "PUT",
                body: {enable}
            }),
            invalidatesTags: (result, error, arg) => {
                //console.log("invalidatesTags")
                return [{type: "Portfolio", id: arg.id}, "Portfolios", "Portfolios list"]
            },
        }),
        //========= UPDATE =========//
        updatePortfolio: builder.mutation<any, { id: string, portfolioUpdateData: IPortfolioUpdate }>({
            query: ({id, portfolioUpdateData}) => {
                const {
                    name, url, enable, category, icons,
                    descriptionEng, descriptionRus,
                     imgsMain, imgsDesktop, imgsMobile
                } = portfolioUpdateData;
                const formData = new FormData();
                formData.append('name', name);
                formData.append('url', url);
                formData.append('descriptionEng', descriptionEng);
                formData.append('descriptionRus', descriptionRus);
                formData.append('enable', String(enable));
                formData.append('icons', icons.join(' '));
                formData.append('category', category.join(' '));

                formData.append('imgsMain', imgsMain);
                formData.append('imgsDesktop', imgsDesktop);
                formData.append('imgsMobile', imgsMobile);
                return ({
                    url: `/portfolio/id/${id}`,
                    method: "PUT",
                    body: formData
                })
            },
            invalidatesTags: (result, error, arg) => [{type: "Portfolio", id: arg.id}, "Portfolios", "Portfolios list"],
        }),
        //========= CREATE =========//
        createPortfolio: builder.mutation<string, { portfolioUpdateData: IPortfolioUpdate }>({
            query: ({portfolioUpdateData}) => {
                const {
                    name, url, enable, category, icons,
                    descriptionEng, descriptionRus,
                    imgsMain, imgsDesktop, imgsMobile
                } = portfolioUpdateData;
                const formData = new FormData();
                formData.append('name', name);
                formData.append('url', url);
                formData.append('descriptionEng', descriptionEng);
                formData.append('descriptionRus', descriptionRus);
                formData.append('enable', String(enable));
                formData.append('icons', icons.join(' '));
                formData.append('category', category.join(' '));

                formData.append('imgsMain', imgsMain);
                formData.append('imgsDesktop', imgsDesktop);
                formData.append('imgsMobile', imgsMobile);
                return ({
                    url: `/portfolio/id`,
                    method: "POST",
                    body: formData
                })
            },
            invalidatesTags: (result, error, arg) => ["Portfolios", "Portfolios list"],
        }),
        //========= DELETE =========//
        deletePortfolio: builder.mutation<string, { id: string }>({
            query: ({id}) => ({
                url: `/portfolio/id/${id}`,
                method: "DELETE",
                body: {}
            }),
            invalidatesTags: (result, error, arg) => ["Portfolios", "Portfolios list"],
        }),
        //========= ORDER =========//
        changeItemsOrder: builder.mutation<string, { changeItemsOrder: ChangeItemsOrderType }>({
            query: ({changeItemsOrder}) => ({
                url: '/portfolio/order',
                method: "PUT",
                body: changeItemsOrder
            }),
            invalidatesTags: (result, error, arg) => ["Portfolios", "Portfolios list"],
        }),
    })
})

export const {
    useGetPortfoliosListQuery,
    useGetAllPortfoliosQuery,
    useGetPortfolioByIdQuery,
    useIncrementCountMutation,
    useUpdateEnableMutation,
    useUpdatePortfolioMutation,
    useCreatePortfolioMutation,
    useDeletePortfolioMutation,
    useChangeItemsOrderMutation,
} = portfolioApi
