import React from "react";
import style from "./header.module.scss";
import {Container} from "../common/Container/Container";
import {svgIcons} from "../../assets/svg/svgIcons";
import {ThemeSwitcher} from "../common/ThemeSwitcher/ThemeSwitcher";
import {Social} from "../common/Social/Social";
import {Links} from "../common/Links/Links";
import {BurgerButton} from "../common/BurgerButton/BurgerButton";
import {HashLink} from "react-router-hash-link";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectThemeType, setBurgerMenu, ThemeTypeEnum} from "../../redux-toolkit-store/appSlice";
import {useMediaQuery} from "@mui/material";

export const Header = () => {
    const themeType = useAppSelector(selectThemeType);
    const dispatch = useAppDispatch();

    return (
        <header id="header">
            <Container classNameInner={style.header}>

                <HashLink to="/#header"
                          className={style.logoBlock}
                          onClick={() => dispatch(setBurgerMenu(false))}
                >
                    {themeType === ThemeTypeEnum.light ? svgIcons.logo_dark : svgIcons.logo_light}
                </HashLink>

                <div className={style.rightBlock}>

                    <div className={style.topRow}>
                        <div className={style.topRowLeft}>
                            <ThemeSwitcher/>
                            <BurgerButton/>
                        </div>

                        <div className={style.topRowRight}>
                            {/*<div data-aos="fade-down">*/}
                            {/*    <LangSwitcher className={style.langSwitcher}/>*/}
                            {/*</div>*/}
                            <div className={style.socialWrapper}>
                                <Social className={style.social}/>
                            </div>
                            {/*<Tel className={style.tel}/>*/}
                        </div>

                    </div>

                    <Links className={style.BottomRow}/>

                </div>
            </Container>

        </header>
    )
}
