import React, {FC, useRef, useState} from "react";
import style from "./PortfolioListItem.module.scss";
import {IPortfolioFormValues, IPortfolioItem} from "../../../types/portfolio.types";
import {IconButton} from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import clsx from "clsx";
import {
    useChangeItemsOrderMutation,
    useGetPortfolioByIdQuery,
    useUpdateEnableMutation,
    useUpdatePortfolioMutation,
    useGetPortfoliosListQuery,
} from "../../../api/portfolio.api";
import {useDrag, useDrop} from "react-dnd";
import type {Identifier, XYCoord} from 'dnd-core';
import {ItemType} from "../../../types/dnd.types";
import {ItemForm} from "../ItemForm/ItemForm";
import {useAppDispatch} from "../../../redux-toolkit-store/hooks";
import {setDeleteModal, setDeleteId} from "../../../redux-toolkit-store/appSlice";
import CircularProgress from "@mui/material/CircularProgress";

interface IDragItem {
    order: number
    id: string
    type: string
}

interface PortfolioListItem {
    item: IPortfolioItem
    items: IPortfolioItem[]
    list: IPortfolioItem[]
    replaceItems: (id1: string, id2: string) => void

}

export const PortfolioListItem: FC<PortfolioListItem> = ({
                                                             item,
                                                             items,
                                                             list,
                                                             replaceItems
                                                         }) => {
    const {
        id,
        name,
        imgs,
        enable,
        viewCount,
        order,
    } = item;

    const {refetch: refetchList} = useGetPortfoliosListQuery();

    //========= DRAG AND DROP =========//
    const [changeItemsOrderMutator, {isLoading}] = useChangeItemsOrderMutation();
    const ref = useRef<HTMLDivElement>(null);
    // карточка как цель перетаскивание
    const [{handlerId}, dropTargetRef] = useDrop<IDragItem,
        { id: string },
        { handlerId: Identifier | null }>({
        accept: ItemType.PortfolioItem,
        // обработчик накрытия цели источником
        hover: (item, monitor) => {
            if (!ref.current) {
                return
            }
            const dragId = item.id;
            const hoveredId = id;
            const dragIndex = item.order; // индекс карточки, КОТОРАЯ перетаскивается и НАКРЫЛА другую карточку
            const hoveredIndex = order; // индекс карточки, которую накрыли
            if (dragIndex === hoveredIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // координата Y середины карточки-цели
            const hoverMiddleY = 0.5 * (hoverBoundingRect.bottom + hoverBoundingRect.top);
            // Координаты указателя мыши = положение источника перетаскивания
            const clientOffsetY = (monitor.getClientOffset() as XYCoord).y;

            // если карточку не перетащили за накрытую ею карточку
            if (
                (dragIndex < hoveredIndex && clientOffsetY < hoverMiddleY) ||
                (dragIndex > hoveredIndex && clientOffsetY > hoverMiddleY)
            ) {
                return
            }
            //console.log(id);
            replaceItems(dragId, hoveredId);
            item.order = hoveredIndex;
        },
        drop: (item, monitor) => {
            //console.log(item);
            //console.log(monitor)
            return ({id: id})
        },
        collect: (monitor) => ({
            handlerId: monitor.getHandlerId(),
        })
    });
    // карточка как источник перетаскивания
    const [{isDragging}, dragSourceRef] = useDrag({
            type: ItemType.PortfolioItem,
            item: ({id, order}),
            collect: (monitor) => ({
                isDragging: monitor.isDragging()
            }),
            end: async (item, monitor) => {
                // объект элемента с неизмененным значением order
                const item_initial = items.find(el => el.id === item.id);

                // если источник удачно брошен на цель  и место перещение - не изначальное место
                if (monitor.getDropResult() && item_initial && item_initial.order !== item.order) {
                    // console.log("changeOrder")
                    // changeOrder({id: item.id, order: item.order});
                    // console.log(list)
                    const changeItemsOrder = list.map(({id, order}) => ({id, order}))
                    await changeItemsOrderMutator({changeItemsOrder}).unwrap();
                }
            }
        }
    )
    dragSourceRef(dropTargetRef(ref));

    //========= CHANGE ENABLE =========//
    const [updateEnable, {isLoading: isLoadingEnable}] = useUpdateEnableMutation();
    const onCheckEnable = async () => {
        try {
            await updateEnable({enable: !enable, id}).unwrap();
            refetchList()
        } catch (e: any) {
            console.log(e.message)
        }
    };

    //========= UPDATE PORTFOLIO =========//
    const [showForm, setShowForm] = useState(false);
    const onEditHandler = () => setShowForm(!showForm);
    const {
        data: portfolioById,
        isFetching: isFetchingPortfolioById,
        refetch: refetchById
    } = useGetPortfolioByIdQuery(id, {
        skip: !showForm
    })
    const [updatePortfolio, resultUpdate] = useUpdatePortfolioMutation();

    //========= OPEN DELETE MODAL =========//
    const dispatch = useAppDispatch();
    const onShowDeleteModal = () => {
        dispatch(setDeleteModal(true));
        dispatch(setDeleteId(item.id));
    };

    return (
        <div className={style.portfolioListItemWrapper}>
            <div className={clsx({
                [style.portfolioListItem]: true,
                [style.portfolioListItem_isDragging]: isDragging,
            })}
                 ref={ref}
            >
                <img src={imgs.main} alt="" className={style.img}/>

                <div className={style.nameWrapper}>
                    <p className={style.name}>{name}</p>
                    <div className={style.viewCountWrapper}>
                        <p className={style.viewCount}>{viewCount}</p>
                        <VisibilityIcon sx={{color: "#FFF"}}/>
                    </div>

                </div>

                <IconButton className={clsx({
                    [style.enableButton]: true,
                    [style.enableButton_disable]: !enable,
                })}
                            onClick={onCheckEnable}
                >
                    {!isLoadingEnable && <CheckIcon sx={{fontSize: "32px", color: enable ? "#FFF" : "transparent"}}/>}
                    {
                        isLoadingEnable && (
                            <div className={style.preloaderWrapper}>
                                <CircularProgress size={36} color="error" className={style.preloader}/>
                            </div>
                        )
                    }
                </IconButton>

                <IconButton className={style.editButton}
                            onClick={onEditHandler}
                >
                    {
                        isFetchingPortfolioById && (
                            <div className={style.preloaderWrapper}>
                                <CircularProgress size={36} sx={{color: "#FFF"}} className={style.preloader}/>
                            </div>
                        )
                    }
                    {
                        !isFetchingPortfolioById && showForm && <CloseIcon sx={{fontSize: "32px", color: "#FFF"}}/>
                    }
                    {
                        !isFetchingPortfolioById && !showForm && <EditIcon sx={{fontSize: "32px", color: "#FFF"}}/>
                    }
                </IconButton>

                <IconButton className={style.deleteButton}
                            onClick={onShowDeleteModal}
                >
                    <DeleteForeverIcon sx={{
                        fontSize: "32px",
                        color: "#FFF"
                    }}/>
                </IconButton>

            </div>

            {
                showForm && portfolioById && (
                    <ItemForm label="Изменить портфолио"
                              initialValues={{
                                  name: portfolioById.name,
                                  url: portfolioById.url,
                                  descriptionEng: portfolioById.description.eng,
                                  descriptionRus: portfolioById.description.rus,
                                  enable: portfolioById.enable,
                                  category: portfolioById.category,
                                  icons: portfolioById.icons,
                                  imgsMain: portfolioById.imgs.main,
                                  imgsDesktop: portfolioById.imgs.desktop,
                                  imgsMobile: portfolioById.imgs.mobile,
                              }}
                              onSubmitHandler={async (portfolioUpdateData: IPortfolioFormValues) => {
                                  try {
                                      const response = await updatePortfolio({id: item.id, portfolioUpdateData}).unwrap();
                                      refetchById();
                                      refetchList();
                                  } catch (e: any) {
                                      console.log(e.message)
                                  }
                              }}
                    />
                )
            }
        </div>

    )
}
