import {H2} from "../../common/H2/H2";
import clsx from "clsx";
import {Container} from "../../common/Container/Container";
import React, {useState} from "react";
import style from "./PortfolioItems.module.scss"
import {translate} from "../../../utils/lang";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectCategory, selectLang, selectThemeType, setCategory} from "../../../redux-toolkit-store/appSlice";
import {useGetAllPortfoliosQuery} from "../../../api/portfolio.api";
import {Preloader} from "../../common/Preloader/Preloader";
import {PortfolioCategoryType} from "../../../types/portfolio.types";
import {PortfolioItem} from "../PortfolioItem/PortfolioItem";
import {Pagination} from "../Pagination/Pagination";

const menuItems = [
    "All",
    "Frontend",
    "Backend",
    "Mobile",
    "Blockchain",
    "Games",
    "Advertising",
];

const menuItemsFront = [
    "All",
    "UI/UX Design",
    "Web Development",
    "Mobile apps",
    "Blockchain and NFTs",
    "Games",
];

export const PortfolioItems = () => {

    const themeType = useAppSelector(selectThemeType);
    const lang = useAppSelector(selectLang);
    const category = useAppSelector(selectCategory);

    const dispatch = useAppDispatch();

    const {data, isFetching} = useGetAllPortfoliosQuery(null, {
        refetchOnMountOrArgChange: true
    });
    // const isFetching = false
    // const data = Object.values(portfolioMock);

    const [currentPage, setCurrentPage] = useState(1);
    const onChangePage = (page: number) => {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
        setCurrentPage(page);
    }

    const selectedItems = data
        ? [...data]
            .sort((itemA, itemB) => {
                if (itemA.order > itemB.order) {
                    return 1
                }
                if (itemA.order < itemB.order) {
                    return -1
                }
                return 0
            })
            .filter(portfolio => {
                if (category === "All" || category === "UI/UX Design") {
                    return true
                }
                if (category === "Web Development") {
                    return (portfolio.category.includes("Frontend" as PortfolioCategoryType)
                        || portfolio.category.includes("Backend" as PortfolioCategoryType))
                }
                if (category === "Mobile apps") {
                    return portfolio.category.includes("Mobile" as PortfolioCategoryType)
                }
                if (category === "Blockchain and NFTs") {
                    return portfolio.category.includes("Blockchain" as PortfolioCategoryType)
                }
                if (category === "Games") {
                    return portfolio.category.includes("Games" as PortfolioCategoryType)
                }
            })
            .filter(portfolio => portfolio.enable)
        : [];

    return (
        <Container classNameInner={style.portfolioItems}>

            {isFetching && <Preloader/>}

            <H2 text="Portfolio"/>

            <div className={style.menu}>
                <div className={style.inner}>
                    {
                        menuItemsFront.map((type, index) => (
                            <div key={type}
                                 className={clsx({
                                     [style.item]: true,
                                     [style.item_light]: themeType === "light",
                                     [style.item_dark]: themeType === "dark",
                                     [style.item_selected_light]: themeType === "light" && type === category,
                                     [style.item_selected_dark]: themeType === "dark" && type === category,
                                 })}
                                 onClick={() => dispatch(setCategory(type))}
                            >
                                <p>{translate(type, lang)}</p>
                            </div>
                        ))
                    }
                </div>
            </div>

            {/*{*/}
            {/*    data && selectedItems.length > 5 && (*/}
            {/*        <Pagination items={selectedItems}*/}
            {/*                    currentPage={currentPage}*/}
            {/*                    onChange={(page) => setCurrentPage(page)}*/}
            {/*        />*/}

            {/*    )*/}
            {/*}*/}

            {
                data &&
                <div className={style.items}>
                    {
                        selectedItems
                            .slice(0 + (currentPage - 1) * 5, 5 + (currentPage - 1) * 5)
                            .map((item, index) => (

                                <PortfolioItem key={item.id}
                                               item={item}
                                               index={index}
                                               lang={lang}
                                               themeType={themeType}
                                />

                            ))
                    }
                </div>
            }

            {
                data && selectedItems.length > 5 && (
                    <Pagination items={selectedItems}
                                currentPage={currentPage}
                                onChange={onChangePage}
                    />

                )
            }

        </Container>
    )
}
