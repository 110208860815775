import React, {FC} from "react";
import {Container} from "../common/Container/Container";
import style from "./dds.module.scss"
import {translate} from "../../utils/lang";
import {ButtonUI} from "../common/ButtonUI/ButtonUI";
import {useMediaQuery} from "@mui/material";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectLang, selectShowModal, setShowModal} from "../../redux-toolkit-store/appSlice";
import { FirstBlock } from "../common/Three/FirstBlock/FirstBlock";
import imgSrc from "../../assets/png/Dds/dds.png";

export const Dds = () => {
    const lang = useAppSelector(selectLang);
    const dispatch = useAppDispatch();
    const textLabel = translate(
        "From turnkey websites to blockchain solutions and NFTs, we do everything you need in today's business.",
        lang
    );

    const isDesktop = useMediaQuery('(min-width:1200px)');

    return (
        <Container classNameInner={style.dds}
                   classNameContainer={style.classNameContainer}
        >
            {
                isDesktop ? (
                    <div className={style.canvasWrapper}>
                        <FirstBlock/>
                    </div>
                ) : (
                    <img src={imgSrc}
                         alt=""
                         className={style.img}
                    />
                )
            }


            <div className={style.secondBlock}>

                <h1 className={style.title}
                    //data-aos="fade-up"
                >
                    <p data-aos="fade-right">Digital</p>
                    <p data-aos="fade-right" data-aos-delay="300">Nova</p>
                </h1>

                <p className={style.text}
                   data-aos="fade-up"
                >
                    {textLabel}
                </p>

                <div className={style.btnWrapper}
                     data-aos="fade-up"
                >
                    <ButtonUI text="i need a website"
                              className={style.btn}

                              onClick={() => dispatch(setShowModal({show: true, type: 'i need a website'}))}
                    />
                    {/*<ButtonAnimation className={style.buttonAnimation}/>*/}
                </div>

            </div>

        </Container>
    )
}
