import React, {FC} from "react";
import style from "./themeSwitcher.module.scss"
import clsx from "clsx";
import {SvgIcon} from "../SvgIcon/SvgIcon";
import {svgIcons} from "../../../assets/svg/svgIcons";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectThemeType, ThemeTypeEnum, setTheme, setThemeType} from "../../../redux-toolkit-store/appSlice";

interface IThemeSwitcher {
    className?: string
}

export const ThemeSwitcher: FC<IThemeSwitcher> = ({
                                                      className,
                                                  }) => {
    const themeType = useAppSelector(selectThemeType);
    const dispatch = useAppDispatch();

    return (
        <div className={clsx(style.themeSwitcher, className)}>
            <div className={clsx({
                [style.btn]: true,
                [style.btn_left_light]: themeType === ThemeTypeEnum.light,
                [style.btn_left_dark]: themeType === ThemeTypeEnum.dark,
            })}
                 onClick={() => {
                     dispatch(setTheme("light2"));
                     dispatch(setThemeType(ThemeTypeEnum.light));
                 }}
            >
                {themeType === ThemeTypeEnum.light ? svgIcons.sun_light : svgIcons.sun_dark}
            </div>

            <div className={clsx({
                [style.btn]: true,
                [style.btn_right_light]: themeType === ThemeTypeEnum.light,
                [style.btn_right_dark]: themeType === ThemeTypeEnum.dark,
            })}
                 onClick={() => {
                     dispatch(setTheme("dark1"));
                     dispatch(setThemeType(ThemeTypeEnum.dark));
                 }}
            >
                <SvgIcon icon={themeType === ThemeTypeEnum.light ? svgIcons.moon_dark : svgIcons.moon_light}/>
            </div>
        </div>
    )
}