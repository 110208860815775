import "./AnimatedIcons.scss"

//========= 0 =========//
export const AnimatedIcon0 = () => {
    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.0098 14.434V13.4292C23.0098 10.1251 25.6856 7.44922 28.9898 7.44922H35.9827C39.2841 7.44922 41.9627 10.1251 41.9627 13.4292L41.9654 14.434"
                stroke="#00FFAF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"
                className="icon0_top"
            />
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M7.44727 22.7206V32.1104C12.6418 35.5364 18.8656 37.936 25.696 38.8866C26.5139 35.9047 29.2033 33.7191 32.4723 33.7191C35.6898 33.7191 38.4333 35.9047 39.1971 38.9137C46.0546 37.9631 52.3027 35.5635 57.5243 32.1104V22.7206C57.5243 18.1326 53.8329 14.4385 49.245 14.4385H15.7537C11.1658 14.4385 7.44727 18.1326 7.44727 22.7206Z"
                  stroke="#00FFAF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"
                  className="icon0_top"
            />
            <path opacity="0.4"
                  d="M7.59375 41.917C7.59375 41.917 7.97833 46.6241 8.06771 48.1082C8.18687 50.0989 8.95604 52.3224 10.2398 53.8662C12.0517 56.0545 14.1858 56.8264 17.035 56.8318C20.3852 56.8372 44.7467 56.8372 48.0969 56.8318C50.946 56.8264 53.0802 56.0545 54.8921 53.8662C56.1758 52.3224 56.945 50.0989 57.0669 48.1082C57.1535 46.6241 57.5381 41.917 57.5381 41.917"
                  stroke="#00FFAF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"
                  className="icon0_bottom"
            />
            <path d="M32.487 45.1701V41.6655" stroke="#00FFAF" strokeWidth="5" strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon0_bottom"
            />

        </svg>
    )
}

//========= 1 =========//
export const AnimatedIcon1 = () => {
    return (
        <svg width="65"
             height="65"
             viewBox="0 0 65 65"
             fill="none"
             xmlns="http://www.w3.org/2000/svg"
             className="icon1"
        >
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M56.3508 20.6469L54.6651 17.7217C53.239 15.2465 50.0785 14.3926 47.5999 15.8128V15.8128C46.42 16.5079 45.0122 16.7051 43.6868 16.3609C42.3614 16.0168 41.2273 15.1596 40.5347 13.9784C40.089 13.2275 39.8496 12.3724 39.8405 11.4993V11.4993C39.8807 10.0996 39.3527 8.74323 38.3766 7.73917C37.4006 6.7351 36.0597 6.16886 34.6594 6.16943H31.2631C29.8912 6.16942 28.5759 6.71608 27.6082 7.68845C26.6405 8.66083 26.1001 9.97875 26.1067 11.3506V11.3506C26.0661 14.183 23.7583 16.4577 20.9256 16.4574C20.0525 16.4483 19.1974 16.2089 18.4466 15.7633V15.7633C15.968 14.343 12.8075 15.1969 11.3813 17.6721L9.57165 20.6469C8.14724 23.119 8.9895 26.2775 11.4557 27.7121V27.7121C13.0588 28.6377 14.0463 30.3481 14.0463 32.1992C14.0463 34.0502 13.0588 35.7607 11.4557 36.6862V36.6862C8.99263 38.1111 8.14945 41.262 9.57165 43.7266V43.7266L11.2822 46.6767C11.9504 47.8824 13.0715 48.7721 14.3975 49.149C15.7235 49.5258 17.1449 49.3587 18.3474 48.6847V48.6847C19.5295 47.9949 20.9381 47.806 22.2602 48.1598C23.5822 48.5136 24.7082 49.3808 25.3878 50.5687C25.8334 51.3196 26.0729 52.1747 26.0819 53.0478V53.0478C26.0819 55.9092 28.4016 58.2289 31.2631 58.2289H34.6594C37.5112 58.229 39.8269 55.9244 39.8405 53.0726V53.0726C39.8339 51.6964 40.3776 50.3747 41.3507 49.4016C42.3238 48.4285 43.6455 47.8848 45.0217 47.8914C45.8926 47.9147 46.7443 48.1532 47.5007 48.5855V48.5855C49.9728 50.0099 53.1313 49.1677 54.5659 46.7015V46.7015L56.3508 43.7266C57.0417 42.5408 57.2313 41.1284 56.8777 39.8023C56.5241 38.4762 55.6564 37.3458 54.4668 36.6614V36.6614C53.2771 35.977 52.4094 34.8466 52.0558 33.5205C51.7022 32.1944 51.8918 30.7821 52.5827 29.5962C53.032 28.8118 53.6824 28.1614 54.4668 27.7121V27.7121C56.9182 26.2783 57.7585 23.1383 56.3508 20.6717V20.6717V20.6469Z"
                  stroke="#E744F6" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <circle opacity="0.4" cx="32.9736" cy="32.1992" r="7.13959" stroke="#E744F6" strokeWidth="5"
                    strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

//========= 2 =========//
export const AnimatedIcon2 = () => {
    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path opacity="0.4" d="M19.6211 40.0327L27.7275 29.4968L36.9743 36.7603L44.9071 26.522" stroke="#F9C835"
                  strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="54.1532" cy="11.3754" r="5.20595" stroke="#F9C835" strokeWidth="5" strokeLinecap="round"
                    strokeLinejoin="round"
                    className="icon2"
            />
            <path
                d="M40.4201 8.4502H20.7366C12.5806 8.4502 7.52344 14.2263 7.52344 22.3823V44.2721C7.52344 52.4281 12.4815 58.1794 20.7366 58.1794H44.0395C52.1954 58.1794 57.2527 52.4281 57.2527 44.2721V25.2084"
                stroke="#F9C835" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}

//========= 3 =========//
export const AnimatedIcon3 = () => {
    return (
        <svg width="65" height="65" viewBox="0 0 65 65" fill="none" xmlns="http://www.w3.org/2000/svg"
             className="icon3"
        >
            <path opacity="0.4" d="M42.5543 20.9268L26.7891 36.692" stroke="#0085FF" strokeWidth="5"
                  strokeLinecap="round" strokeLinejoin="round"/>
            <path
                d="M26.7893 36.6927L8.33204 25.407C5.91349 23.9283 6.4037 20.2802 9.12829 19.492L52.7054 6.9037C55.1835 6.18599 57.4747 8.4989 56.7327 10.9689L43.8031 54.2047C42.9933 56.9131 39.3614 57.3789 37.8935 54.9631L26.7893 36.6927"
                stroke="#0085FF" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    )
}


export const animatedIcons = [
    <AnimatedIcon0/>,
    <AnimatedIcon1/>,
    <AnimatedIcon2/>,
    <AnimatedIcon3/>,
]
