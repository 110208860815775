import * as React from "react";
import {FC, useEffect, useRef, useState} from "react";
import * as THREE from "three";
import {BufferAttribute} from "three";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {OrbitControls, PerspectiveCamera} from "@react-three/drei";
import {Bloom, EffectComposer} from "@react-three/postprocessing";
import {animated, config, useSpring} from "@react-spring/three";
// @ts-ignore
import IsScrolling from "react-is-scrolling";
import {ThemeTypeEnum} from "../../../../redux-toolkit-store/appSlice";
import {pointShaderMaterialDark, pointShaderMaterialLight} from "./pointShaderMaterial";

const a_x = 200;
const a_y = 200;
const a_z = 600;
const pointsCount = 10000;
const speed = 30;
const k = 3;
const SPEED_RADIAL = 0.3;

const pulsarCount = 20;

const positions = Array
    .from({length: pulsarCount}, (_, i) => i)
    .map(key => new THREE.Vector3(
        (Math.random() - 0.5) * a_x,
        (Math.random() - 0.5) * a_y,
        0
        )
    )

//========= GET COLOR BY INDEX =========//
const getColorByIndex = (index: number) => {
    switch (index % 3) {
        case 0: {
            return new THREE.Color(5, 1, 1)
        }
        case 1: {
            return new THREE.Color(1, 5, 5)
        }
        case 2: {
            return new THREE.Color(1, 1, 5)
        }
        default: {
            return new THREE.Color(5, 1, 1)
        }
    }
}


//========= PULSARS =========//
const Pulsars = () => {
    const ref = useRef<THREE.Group>(null!);

    const {viewport, camera} = useThree();
    const {width, height} = viewport.getCurrentViewport(camera, [0, 0, 0]);

    // const positions = Array
    //     .from({length: pulsarCount}, (_, i) => i)
    //     .map(key => new THREE.Vector3(
    //         (Math.random() - 0.5) * width,
    //         (Math.random() - 0.5) * height,
    //         0)
    //     )

    useFrame((state, delta) => {
        if (delta < 0.1) {
            ref.current.rotation.z += delta * SPEED_RADIAL;
        }
    })


    const [tik, setTik] = useState(1);
    const {scale} = useSpring({
        scale: tik % 2 === 0 ? 1.2 : 1,
        config: config.wobbly
    })

    useEffect(() => {
        const timer = setInterval(() => {
            setTik(tik + 1)
        }, 300)
        return () => clearInterval(timer);
    }, [tik])

    return (
        <group ref={ref}>
            {
                Array
                    .from({length: pulsarCount}, (_, i) => i)
                    .map(key => (
                        <animated.mesh key={key}
                                       scale={scale}
                                       position={positions[key]}
                        >
                            <sphereGeometry args={[0.5]}/>
                            <meshBasicMaterial color={getColorByIndex(key)} toneMapped={false}/>
                        </animated.mesh>
                    ))
            }
        </group>

    )
}

//========= POINTS =========//
const PointsLight: FC<{ isScrolling: boolean }> = ({isScrolling}) => {
    const {viewport, camera} = useThree();
    const {width, height} = viewport.getCurrentViewport(camera, [0, 0, a_z - 10]);

    const positions = [] as number[];
    const scales = [] as number[];
    const colors = [] as number[]

    for (let i = 0; i < pointsCount; i++) {
        const x = (Math.random() - 0.5) * a_x;
        const y = (Math.random() - 0.5) * a_y;
        const z = Math.random() * a_z;
        positions.push(...[x, y, z])
        scales.push(1);
        colors.push(...[0, 0, 0])
    }

    const positionAttribute = new BufferAttribute(new Float32Array(positions), 3);
    const scaleAttribute = new BufferAttribute(new Float32Array(scales), 1);
    const colorAttribute = new BufferAttribute(new Float32Array(colors), 3);

    const ref = useRef<THREE.Points>(null!);

    useFrame((state, delta) => {
        if (delta < 0.1) {
            const positions = Array.from(ref.current.geometry.getAttribute("position").array);
            for (let i = 1; i <= pointsCount; i++) {
                const x = positions[3 * i - 3];
                const y = positions[3 * i - 2];
                const radius = Math.sqrt((x ** 2) + (y ** 2));
                let alpha = Math.atan(y / x);
                if (x < 0) {
                    alpha += Math.PI;
                }
                positions[3 * i - 3] = radius * Math.cos(alpha + (isScrolling ? k : 1) * SPEED_RADIAL * delta);
                positions[3 * i - 2] = radius * Math.sin(alpha + (isScrolling ? k : 1) * SPEED_RADIAL * delta);

                if (positions[3 * i - 1] > a_z) {
                    positions[3 * i - 1] = 0
                } else {
                    positions[3 * i - 1] += (isScrolling ? k : 1) * speed * delta;
                }
            }

            const positionAttributeUpdate = new BufferAttribute(new Float32Array(positions), 3);
            ref.current.geometry.setAttribute("position", positionAttributeUpdate);
        }
    })

    return (
        <points ref={ref}>
            <bufferGeometry>
                <bufferAttribute attach="attributes-position" {...positionAttribute} needsUpdate={true}/>
                <bufferAttribute attach="attributes-scale" {...scaleAttribute} needsUpdate={true}/>
                <bufferAttribute attach="attributes-customColor" {...colorAttribute} needsUpdate={true}/>
            </bufferGeometry>
            <shaderMaterial attach="material"
                            {...pointShaderMaterialLight()}
                            needsUpdate={true}
            />
        </points>
    )
}

const PointsDark: FC<{ isScrolling: boolean }> = ({isScrolling}) => {
    const {viewport, camera} = useThree();
    const {width, height} = viewport.getCurrentViewport(camera, [0, 0, a_z - 10]);

    const positions = [] as number[];
    const scales = [] as number[];
    const colors = [] as number[]

    for (let i = 0; i < pointsCount; i++) {
        const x = (Math.random() - 0.5) * a_x;
        const y = (Math.random() - 0.5) * a_y;
        const z = Math.random() * a_z;
        positions.push(...[x, y, z])
        scales.push(1);
        colors.push(...[0, 0, 0])
    }

    const positionAttribute = new BufferAttribute(new Float32Array(positions), 3);
    const scaleAttribute = new BufferAttribute(new Float32Array(scales), 1);
    const colorAttribute = new BufferAttribute(new Float32Array(colors), 3);

    const ref = useRef<THREE.Points>(null!);

    useFrame((state, delta) => {
        if (delta < 0.1) {
            const positions = Array.from(ref.current.geometry.getAttribute("position").array);
            for (let i = 1; i <= pointsCount; i++) {
                const x = positions[3 * i - 3];
                const y = positions[3 * i - 2];
                const radius = Math.sqrt((x ** 2) + (y ** 2));
                let alpha = Math.atan(y / x);
                if (x < 0) {
                    alpha += Math.PI;
                }
                positions[3 * i - 3] = radius * Math.cos(alpha + (isScrolling ? k : 1) * SPEED_RADIAL * delta);
                positions[3 * i - 2] = radius * Math.sin(alpha + (isScrolling ? k : 1) * SPEED_RADIAL * delta);

                if (positions[3 * i - 1] > a_z) {
                    positions[3 * i - 1] = 0
                } else {
                    positions[3 * i - 1] += (isScrolling ? k : 1) * speed * delta;
                }
            }

            const positionAttributeUpdate = new BufferAttribute(new Float32Array(positions), 3);
            ref.current.geometry.setAttribute("position", positionAttributeUpdate);
        }
    })

    return (
        <points ref={ref}>
            <bufferGeometry>
                <bufferAttribute attach="attributes-position" {...positionAttribute} needsUpdate={true}/>
                <bufferAttribute attach="attributes-scale" {...scaleAttribute} needsUpdate={true}/>
                <bufferAttribute attach="attributes-customColor" {...colorAttribute} needsUpdate={true}/>
            </bufferGeometry>
            <shaderMaterial attach="material"
                            {...pointShaderMaterialDark()}
                            needsUpdate={true}
            />
        </points>
    )
}

// {...(themeType === ThemeTypeEnum.dark ? pointShaderMaterialLight() : pointShaderMaterialDark())}

//========= STARS =========//
interface IStarsRotate {
    isScrolling: boolean
    themeType: ThemeTypeEnum
}

const StarsRotate: FC<IStarsRotate> = ({isScrolling, themeType}) => {
    return (
        <Canvas gl={{antialias: true}}>

            {
                themeType === ThemeTypeEnum.dark ? <PointsLight isScrolling={isScrolling}/> : <PointsDark isScrolling={isScrolling}/>
            }

            {/*<Pulsars/>*/}

            <PerspectiveCamera makeDefault position={[0, 0, a_z - 10]} zoom={4}/>
            <OrbitControls target={[0, 0, 0]} enableRotate={false} enableZoom={false}/>


            <EffectComposer>
                <Bloom mipmapBlur luminanceThreshold={1}/>
            </EffectComposer>

        </Canvas>
    )
}

const StarsRotateLight: FC<IStarsRotate> = ({isScrolling, themeType}) => {
    return (
        <Canvas gl={{antialias: true}}>

            <PointsLight isScrolling={isScrolling}/>

            {/*<Pulsars/>*/}

            <PerspectiveCamera makeDefault position={[0, 0, a_z - 10]} zoom={4}/>
            <OrbitControls target={[0, 0, 0]} enableRotate={false} enableZoom={false}/>


            <EffectComposer>
                <Bloom mipmapBlur luminanceThreshold={1}/>
            </EffectComposer>

        </Canvas>
    )
}

const StarsRotateDark: FC<IStarsRotate> = ({isScrolling, themeType}) => {
    return (
        <Canvas gl={{antialias: true}}>

            <PointsDark isScrolling={isScrolling}/>

            {/*<Pulsars/>*/}

            <PerspectiveCamera makeDefault position={[0, 0, a_z - 10]} zoom={4}/>
            <OrbitControls target={[0, 0, 0]} enableRotate={false} enableZoom={false}/>


            <EffectComposer>
                <Bloom mipmapBlur luminanceThreshold={1}/>
            </EffectComposer>

        </Canvas>
    )
}

export const StarsScroll = IsScrolling(StarsRotate);
export const StarsScrollDark = IsScrolling(StarsRotateDark);
export const StarsScrollLight = IsScrolling(StarsRotateLight);
