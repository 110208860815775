import style from "./PortfolioPage.module.scss"
import React, {memo} from "react";
import {BigForm} from "../common/BigForm/BigForm";
import {PortfolioItems} from "./PortfolioItems/PortfolioItems";
import clsx from "clsx";
import "../B0_HomePage/wave.scss";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectThemeType, ThemeTypeEnum} from "../../redux-toolkit-store/appSlice";
import wave_light from "../../assets/png/wave_light-min.png";
import wave_dark from "../../assets/png/wave_dark-min.png";

export const PortfolioPage = () => {
    const themeType = useAppSelector(selectThemeType);
    const wave = themeType === ThemeTypeEnum.light ? wave_light : wave_dark;

    return (
        <div className={style.portfolioPage}>
            <div className={style.images}>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
            </div>

            <div className={style.content}>

                <PortfolioItems/>

                <div id="contacts">
                    <BigForm title="Do you want to order website development?"
                             className={style.bigForm}
                    />
                </div>

            </div>
        </div>
    )
}

export const PortfolioPageMemo = memo(PortfolioPage)
