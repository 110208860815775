import React, {FC} from "react";
import {HashRouter} from "react-router-dom";
import {App} from "./App";
import {Provider} from "react-redux";
import {store} from "../../redux-toolkit-store/store";
import {HTML5Backend} from "react-dnd-html5-backend";
import {DndProvider} from "react-dnd";

export const AppContainer: FC = () => {
    return (
        <DndProvider backend={HTML5Backend}>
            <HashRouter>
                <Provider store={store}>
                    <App/>
                </Provider>
            </HashRouter>
        </DndProvider>

    )
}
