import React, {FC} from "react";
import style from "./h2.module.scss"
import clsx from "clsx";
import {translate} from "../../../utils/lang";
import {useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectLang} from "../../../redux-toolkit-store/appSlice";

interface IH2 {
    text: string
    className?: string
    translated?: boolean
}

export const H2: FC<IH2> = ({text, className, translated = true}) => {
    const lang = useAppSelector(selectLang);
    return (
        <div className={style.h2Wrapper} data-aos="fade-right">
            <h2 className={clsx(style.h2, className)}>
                {translated ? translate(text, lang) : text}
            </h2>
            <div className={style.line}/>
        </div>
    )
}