import React, {useEffect} from 'react';
import style from "./app.module.scss"
import clsx from "clsx";
import {Header} from "../A1_Header/Header";
import {Route, Routes} from "react-router-dom";
import {HomePage} from "../B0_HomePage/HomePage";
import {PortfolioPageMemo} from "../B1_PortfolioPage/PortfolioPage";
import {Footer} from "../A2_Footer/Footer";
import {WorkPageMemo} from "../B2_WorkPage/WorkPage";
import {BurgerMenu} from "../A3_Modals/BurgerMenu/BurgerMenu";
import {OnSubmitModal} from "../A3_Modals/OnSubmitModal/OnSubmitModal";
import AOS from "aos";
import "aos/dist/aos.css";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {
    selectBurgerMenu,
    selectHowMuchIsCard,
    selectShowModal,
    selectTheme,
    } from '../../redux-toolkit-store/appSlice';
import {AlertCustom} from "../common/AlertCustom/AlertCustom";
import {INeedWebsiteModal} from "../A3_Modals/INeedWebsiteModal/INeedWebsiteModal";
import {HowMuchIsModal} from "../A3_Modals/HowMuchIsModal/HowMuchIsModal";
import { AdminPage } from '../B4_AdminPage/AdminPage';
import {LoginForm} from "../A4_LoginForm/LoginForm";

export type LangType = "eng" | "rus";

export const App = () => {
    const theme = useAppSelector(selectTheme);
    const burgerMenu = useAppSelector(selectBurgerMenu);
    const showModal = useAppSelector(selectShowModal);
    const howMuchIsCard = useAppSelector(selectHowMuchIsCard);

    useEffect(() => {
        AOS.init({
            duration: 1500,
            once: false,
        });
        AOS.refresh()
    }, []);

    return (
        <div className={clsx({
            [style.app]: true,
            //[style.app_light1]: theme === "light1",
            [style.app_light2]: theme === "light2",
            [style.app_dark1]: theme === "dark1",
            //[style.app_dark2]: theme === "dark2",
            [style.app_hide]: burgerMenu // || showModal.show,
        })}
        >
            <Header />
            <BurgerMenu />
            <LoginForm/>

            {showModal.show && showModal.type === "on submit" && <OnSubmitModal/>}
            {showModal.show && showModal.type === 'i need a website' && <INeedWebsiteModal/>}
            {showModal.show && showModal.type === 'how much is' && howMuchIsCard && <HowMuchIsModal card={howMuchIsCard}/>}

            <AlertCustom/>

            <Routes>
                <Route path='/' element={<HomePage/>}/>
                <Route path='/admin' element={<AdminPage/>}/>
                <Route path='/portfolio' element={<PortfolioPageMemo/>}/>
                <Route path='/portfolio/:id' element={<WorkPageMemo/>}/>
                {/*<Route path='/shop' element={<ShopPage/>}/>*/}
            </Routes>

            <Footer/>
        </div>
    );
}

