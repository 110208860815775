import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ISendBrief, ISendEmail, mailAPI} from "../api/mail.api";
import {IHowMuchIsCard} from "../components/C2_HowMuchIs/constants";

export interface IShowModal {
    show: boolean
    type: string // 'on submit', 'buy website', 'how much is', "i need a website"
}

export enum ThemeTypeEnum {
    "light" = "light",
    "dark" = "dark"
}

export enum LangEnum {
    "eng" = "eng",
    "rus" = "rus"
}

//==================== INITIAL STATE ===================//
const initialState = {
    loading: false,
    burgerMenu: false,

    // запоминаем настройки темы до изменения
    themeBefore: "light2",
    themeTypeBefore: "light" as ThemeTypeEnum,

    theme: "light2",
    themeType: "light" as ThemeTypeEnum,

    lang: "eng" as LangEnum,
    showModal: {
        show: false,
        type: '',
    } as IShowModal,
    alert: false,
    alertText: "",
    howMuchIsCard: null as null | IHowMuchIsCard,
    deleteModal: false,
    deleteId: "",
    category: "All" as string,
    preloader: true
}

export type InitialStateType = typeof initialState

//==================== SLICE ===================//
export const appSlice = createSlice({
    name: "app",
    initialState: initialState as InitialStateType,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setBurgerMenu: (state, action: PayloadAction<boolean>) => {
            state.burgerMenu = action.payload
        },
        setTheme: (state, action: PayloadAction<string>) => {
            state.themeBefore = state.theme
            state.theme = action.payload
        },
        setThemeType: (state, action: PayloadAction<ThemeTypeEnum>) => {
            state.themeTypeBefore = state.themeType
            state.themeType = action.payload
        },
        setLang: (state, action: PayloadAction<LangEnum>) => {
            state.lang = action.payload
        },
        setShowModal: (state, action: PayloadAction<IShowModal>) => {
            state.showModal = action.payload
        },
        setAlert: (state, action: PayloadAction<boolean>) => {
            state.alert = action.payload
        },
        setAlertText: (state, action: PayloadAction<string>) => {
            state.alertText = action.payload
        },
        setHowMuchIsCard: (state, action: PayloadAction<IHowMuchIsCard>) => {
            state.howMuchIsCard = action.payload
        },
        setDeleteModal: (state, action: PayloadAction<boolean>) => {
            state.deleteModal = action.payload
        },
        setDeleteId: (state, action: PayloadAction<string>) => {
            state.deleteId = action.payload
        },
        setCategory: (state, action: PayloadAction<string>) => {
            state.category = action.payload
        },
        setPreloader: (state, action: PayloadAction<boolean>) => {
            state.preloader = action.payload
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(sendEmail.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendEmail.fulfilled, (state, action) => {
                state.showModal = {show: true, type: 'on submit'};
                state.loading = false;
            })
            .addCase(sendEmail.rejected, (state, action) => {
                state.loading = false;
                state.showModal = {show: false, type: ''};
                state.alert = true;
                if (action.payload) {
                    state.alertText = action.payload;
                } else {
                    state.alertText = "Error";
                }
            })
            .addCase(sendBrief.pending, (state) => {
                state.loading = true;
            })
            .addCase(sendBrief.fulfilled, (state, action) => {
                state.showModal = {show: true, type: 'on submit'};
                state.loading = false;
            })
            .addCase(sendBrief.rejected, (state, action) => {
                state.loading = false;
                state.showModal = {show: false, type: ''};
                state.alert = true;
                if (action.payload) {
                    state.alertText = action.payload;
                } else {
                    state.alertText = "Error";
                }
            })
    }
})

//==================== ACTION CREATORS ===================//
export const {
    setLoading,
    setBurgerMenu,
    setTheme,
    setThemeType,
    setLang,
    setShowModal,
    setAlert,
    setAlertText,
    setHowMuchIsCard,
    setDeleteModal,
    setDeleteId,
    setCategory,
    setPreloader,
} = appSlice.actions

//=================== ASYNC THUNK ==================//
//================ SEND EMAIL ======================
export const sendEmail = createAsyncThunk<any, ISendEmail, { rejectValue: string }>(
    "app/sendEmail",
    async (data, {rejectWithValue}) => {
        try {
            const response = await mailAPI.sendEmail(data);
            return response;
        } catch (e: any) {
            //console.log(e.response.data.message);
            return rejectWithValue(e.response.data.message)
        }
    }
);

//================ SEND BRIEF ======================
export const sendBrief = createAsyncThunk<any, ISendBrief, { rejectValue: string }>(
    "app/sendEmail/brief",
    async (data, {rejectWithValue}) => {
        try {
            const response = await mailAPI.sendBrief(data);

            return response;
        } catch (e: any) {
            //console.log(e.response.data.message);
            return rejectWithValue(e.response.data.message)
        }
    }
);


//=================== SELECTORS ==================//
export const selectLoading = (state: RootState) => state.app.loading;
export const selectBurgerMenu = (state: RootState) => state.app.burgerMenu;
export const selectTheme = (state: RootState) => state.app.theme;
export const selectThemeType = (state: RootState) => state.app.themeType;
export const selectThemeBefore = (state: RootState) => state.app.themeBefore;
export const selectThemeTypeBefore = (state: RootState) => state.app.themeTypeBefore;
export const selectLang = (state: RootState) => state.app.lang;
export const selectShowModal = (state: RootState) => state.app.showModal;
export const selectAlert = (state: RootState) => state.app.alert;
export const selectAlertText = (state: RootState) => state.app.alertText;
export const selectHowMuchIsCard = (state: RootState) => state.app.howMuchIsCard;
export const selectDeleteModal = (state: RootState) => state.app.deleteModal;
export const selectDeleteId = (state: RootState) => state.app.deleteId;
export const selectCategory = (state: RootState) => state.app.category;
export const selectPreloader = (state: RootState) => state.app.preloader;

export const appReducer = appSlice.reducer
