import React, {FC, useState} from "react";
import {Plane, Text3D, useTexture} from "@react-three/drei";
import * as THREE from "three";
import {animated, useSpring} from "@react-spring/three";
import fontUrl from "../../../../assets/fonts/Montserrat_Bold.json";

const params = {
    factor: 16 / 9,
    frameOffset: 0.06, // отступ от экрана внешней части остнования (рамки)
    frameRadiusInner: 0.025,
    frameRadiusOuter: 0.05,
    frameHeight: 0.08, // толщина рамки
    baseHeight: 0.06, // толщина основания
    baseOffset: 0.01,
    frameBevel: 0.002, // фаска рамки
    baseBevel: 0.001, // фаска основания
}


export const TabletModel: FC<{ src: string }> = ({src}) => {
    const [map] = useTexture([src])
    map.repeat = new THREE.Vector2(1, 1);

    const baseColor = "#333";
    const frameColor = "#151515";
    const textColor = "#151515";

    //const [hover, setHover] = useState(false);

    // const {scale, frameColor, baseColor} = useSpring({
    //     scale: hover ? 1.05 : 1,
    //     frameColor: hover ? "#777" : "#151515",
    //     baseColor: !hover ? "#777" : "#151515",
    // });

    return (
        <group // onPointerOver={() => setHover(true)}
            // onPointerOut={() => setHover(false)}
            //scale={scale}
        >
            {/*экран*/}
            <Plane args={[params.factor, 1]}
                   position={[0, 0, 0.5 * (params.frameHeight + params.frameBevel)]}
            >
                <meshStandardMaterial map={map}/>
            </Plane>

            {/*задник*/}
            <Plane args={[params.factor, 1]}
                   position={[0, 0, -0.5 * params.baseHeight - 1 * params.baseBevel - 0.0125]}
                   rotation-y={Math.PI}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={frameColor}
                                      emissive={frameColor}
                                      roughness={0.2}
                                      metalness={1}
                />
            </Plane>

            {/*рамка*/}
            <mesh geometry={getFrameGeometry()}
                  position={[0, 0, -0.5 * (params.frameHeight + params.frameBevel)]}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={frameColor}
                                      emissive={frameColor}
                                      roughness={0.2}
                                      metalness={1}
                />
            </mesh>

            {/*основа*/}
            <mesh geometry={getBaseGeometry()}
                  position={[0, 0, -0.5 * (params.baseHeight + 0 * params.baseBevel)]}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={baseColor}
                                      emissive={baseColor}
                                      roughness={0.4}
                                      metalness={1}
                />
            </mesh>

            <Text3D
                // @ts-ignore
                font={fontUrl}
                size={0.025}
                height={0.01}
                position={[-0.14, -0.54, 0.5 * params.frameHeight + 0.001]}

            >
                DIGIT NOVA
                {/*@ts-ignore*/}
                <meshStandardMaterial color={baseColor}/>
            </Text3D>

        </group>
    )
}

//========= GET SCREEN HOLE =========//
const getScreenHole = () => {
    const r = params.frameRadiusInner;
    const pi = Math.PI;

    const h = 1;
    const w = 1 * params.factor;
    const screenPath = new THREE.Path();

    screenPath.moveTo(0.5 * w, 0.5 * h - r);

    screenPath.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    screenPath.lineTo(-0.5 * w + r, 0.5 * h);

    screenPath.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    screenPath.lineTo(-0.5 * w, -0.5 * h + r);

    screenPath.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    screenPath.lineTo(0.5 * w - r, -0.5 * h);

    screenPath.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    screenPath.lineTo(0.5 * w, 0.5 * h - r);

    return screenPath;
}

//========= GET FRAME SHAPE =========//
const getFrameShape = () => {
    const r = params.frameRadiusOuter;
    const pi = Math.PI;

    const h = 1 + 2 * params.frameOffset;
    const w = 16 / 9 + 2 * params.frameOffset;
    const shape = new THREE.Shape();

    shape.moveTo(0.5 * w, 0.5 * h - r);

    shape.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    shape.lineTo(-0.5 * w + r, 0.5 * h);

    shape.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    shape.lineTo(-0.5 * w, -0.5 * h + r);

    shape.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    shape.lineTo(0.5 * w - r, -0.5 * h);

    shape.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    shape.lineTo(0.5 * w, 0.5 * h - r);
    const screenHole = getScreenHole();
    shape.holes.push(screenHole);
    return shape;
}

//========= GET FRAME GEOMETRY =========//
const getFrameGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.frameHeight, // величина выдавливания
        bevelEnabled: true, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.frameBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.frameBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(getFrameShape(), extrudeSettings)
}

//========= GET BASE SHAPE =========//
const getBaseShape = () => {
    const r = params.frameRadiusOuter + params.baseOffset;
    const pi = Math.PI;

    const h = 1 + 2 * (params.frameOffset + params.baseOffset);
    const w = 16 / 9 + 2 * (params.frameOffset + params.baseOffset);
    const shape = new THREE.Shape();

    shape.moveTo(0.5 * w, 0.5 * h - r);

    shape.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    shape.lineTo(-0.5 * w + r, 0.5 * h);

    shape.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    shape.lineTo(-0.5 * w, -0.5 * h + r);

    shape.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    shape.lineTo(0.5 * w - r, -0.5 * h);

    shape.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    shape.lineTo(0.5 * w, 0.5 * h - r);
    return shape;
}

//========= GET BASE GEOMETRY =========//
const getBaseGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.baseHeight, // величина выдавливания
        bevelEnabled: false, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.baseBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.baseBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(getBaseShape(), extrudeSettings)
}
