import React, {FC} from "react";
import style from "./social.module.scss";
import clsx from "clsx";
import {svgIcons} from "../../../assets/svg/svgIcons";
import {useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectThemeType, ThemeTypeEnum} from "../../../redux-toolkit-store/appSlice";

interface ISocial {
    className?: string
}

const icons = [
    {icon: svgIcons.telegram, href: "https://t.me/Digital_inc"},
    // {icon: svgIcons.phone, href: ""},
    // {icon: svgIcons.instagram, href: ""},
];

export const Social: FC<ISocial> = ({className}) => {
    const themeType = useAppSelector(selectThemeType);

    return (
        <div className={clsx({
            [style.social]: true,
            [style.social_light]: themeType === ThemeTypeEnum.light,
            [style.social_dark]: themeType === ThemeTypeEnum.dark,
        }, className)}>
            {
                icons.map((icon, index) => (
                    <a key={index}
                       href={icon.href}
                       target="_blank"
                       rel="noopener nofollow noreferrer"
                       className={clsx({
                           [style.icon]: true,
                           [style.icon_light]: themeType === ThemeTypeEnum.light,
                           [style.icon_dark]: themeType === ThemeTypeEnum.dark,
                       })}
                    >
                        {icon.icon}
                    </a>
                ))
            }
        </div>
    )
}