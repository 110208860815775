import {Canvas, useFrame, useThree} from "@react-three/fiber"
import {DepthOfField, EffectComposer} from "@react-three/postprocessing";
import {ThemeTypeEnum} from "../../../../redux-toolkit-store/appSlice";
import React, {FC, useRef, useState} from "react";
import {Instance, Instances, Stats,} from "@react-three/drei";
import * as THREE from "three";
import {makeArray} from "../../../../utils/makeArray";
import {TextGeometry} from "three/examples/jsm/geometries/TextGeometry";
import {Font, FontLoader} from "three/examples/jsm/loaders/FontLoader";
import fontUrl from "../../../../assets/fonts/Montserrat_Bold.json";

const easing = (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2));
const speed = 2;
const count = 30;
const depth = 80;

//========= INSTANCES WEB RAIN =========//
export const InstancesWebRain: FC<{ themeType: ThemeTypeEnum }> = ({themeType}) => {

    return (
        <Canvas gl={{antialias: false}}
                dpr={[1, 1.5]}
                camera={{position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15}}>

            <color attach="background" args={[themeType === ThemeTypeEnum.light ? "#EFEEF3" : "#25303F"]}/>

            <ambientLight intensity={0.3} color="#FFF"/>
            <directionalLight position={[-40, 40, 40]} intensity={5}/>

            <InstancesWebs/>

            {process.env.NODE_ENV === 'development' && <Stats/>}

            <EffectComposer multisampling={0}>
                <DepthOfField target={[0, 0, 60]}
                              focalLength={0.8} // 0.4
                              bokehScale={10} // 7
                              height={700}
                />
            </EffectComposer>

        </Canvas>
    )
}

//========= INSTANCES WEBS =========//
const InstancesWebs = () => {
    const font = new FontLoader().parse(fontUrl);

    return (
        <>
            {
                true && (
                    <Instances range={count}
                               geometry={new TextGeometry("Web", {
                                   font,
                                   size: 3,
                                   height: 1.5,
                               })}
                    >
                        <meshStandardMaterial
                            color="#845eff"
                            opacity={0.75}
                            transparent={true}
                            side={THREE.DoubleSide}
                            emissive="#845eff"
                            roughness={0.4}
                            metalness={1}
                        />
                        {
                            makeArray(count).map(index => (
                                <Web key={index}
                                       index={index}
                                       speed={speed}
                                       z={Math.round(easing(index / count) * depth)}
                                />
                            ))
                        }
                    </Instances>
                )
            }
        </>

    )
}

//========= WEB =========//
interface IWeb {
    index: number
    z: number
    speed: number
}

export const Web: FC<IWeb> = ({index, z, speed}) => {
    const ref = useRef<THREE.Group>(null!)
    const {viewport, camera} = useThree();
    const {width, height} = viewport.getCurrentViewport(camera, [0, 0, -z]);

    const [data] = useState({
        y: THREE.MathUtils.randFloatSpread(height * 2),
        x: THREE.MathUtils.randFloatSpread(2),
        spin: THREE.MathUtils.randFloat(8, 12),
        rX: Math.random() * Math.PI,
        rZ: Math.random() * Math.PI
    });

    useFrame((state, dt) => {
        if (dt < 0.1) {
            ref.current.position.set(
                (index === 0 ? 0 : data.x * width),
                (data.y -= dt * speed),
                -z
            );
        }
        ref.current.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin));
        if (data.y < -height * (index === 0 ? 4 : 1)) {
            data.y = +(height * (index === 0 ? 4 : 1))
        }
    })

    return (
        <group ref={ref}
               scale={0.75}
        >
            <Instance/>
        </group>
    )
}
