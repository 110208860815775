import React, {FC, useState} from "react";
import {Plane, useTexture} from "@react-three/drei";
import * as THREE from "three";
import {animated, useSpring} from "@react-spring/three";

const params = {
    factor: 2,
    screenOffset: 0.05,
    screenRadius: 0.1,
    screenBevel: 0.02,
    screenDepth: 0.05,
    maskOffset: 0.01,
    baseOffset: 0.01,
    baseBevel: 0.04,
}

//========= MOBILE =========//
export const MobileModel: FC<{ src: string }> = ({src}) => {
    //const [hover, setHover] = useState(false);

    // const {screenColor, baseColor} = useSpring({
    //     screenColor: hover ? "#777" : "#222",
    //     baseColor: hover ? "#222" : "#777",
    //     //scale: hover ? 1.05 : 1,
    // });

    const screenColor = "#222";
    const baseColor = "#777";

    return (
        <group //onPointerOver={() => setHover(true)}
               //onPointerOut={() => setHover(false)}
               //scale={scale}
        >

            <WindowModel src={src}/>

            {/*window mask*/}
            <mesh geometry={getMaskGeometry()}
                  position={[0, params.screenOffset, params.screenDepth + params.screenBevel + 0.0011]}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={screenColor}
                                               emissive={screenColor}
                                               roughness={0.2}
                                               metalness={1}
                />
            </mesh>

            {/*screen*/}
            <mesh geometry={getScreenGeometry()}
                  position={[0, 0, 0]}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={screenColor}
                                               emissive={screenColor}
                                               roughness={0.2}
                                               metalness={1}
                />
            </mesh>

            {/*base*/}
            <mesh geometry={getBaseGeometry()}
                  position={[0, 0, -2 * params.screenBevel]}
            >
                {/*@ts-ignore*/}
                <meshStandardMaterial color={baseColor}
                                               emissive={baseColor}
                                               roughness={0.2}
                                               metalness={1}
                />
            </mesh>
        </group>
    )
}

//========= WINDOW MODEL =========//
export const WindowModel: FC<{ src: string }> = ({src}) => {
    const [map] = useTexture([src])
    map.repeat = new THREE.Vector2(1, 1);

    return (
        <Plane args={[1, 1 * params.factor]}
               position={[0, params.screenOffset, params.screenDepth + params.screenBevel + 0.001]}
        >
            <meshStandardMaterial map={map}/>
        </Plane>
    )
}


//========= GET SCREEN SHAPE =========//
const getScreenShape = () => {
    const r = params.screenRadius;
    const pi = Math.PI;

    const h = 1 * params.factor + 4 * params.screenOffset;
    const w = 1 + 2 * params.screenOffset;
    const shape = new THREE.Shape();

    shape.moveTo(0.5 * w, 0.5 * h - r);

    shape.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    shape.lineTo(-0.5 * w + r, 0.5 * h);

    shape.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    shape.lineTo(-0.5 * w, -0.5 * h + r);

    shape.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    shape.lineTo(0.5 * w - r, -0.5 * h);

    shape.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    shape.lineTo(0.5 * w, 0.5 * h - r);
    return shape;
}

//========= GET SCREEN GEOMETRY =========//
export const getScreenGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.screenDepth, // величина выдавливания
        bevelEnabled: true, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.screenBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.screenBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(getScreenShape(), extrudeSettings)
}

//========= GET MASK HOLE SHAPE =========//
const getMaskHolePath = () => {
    const r = params.screenRadius - params.screenOffset;
    const pi = Math.PI;

    const h = params.factor;
    const w = 1;
    const path = new THREE.Path();

    path.moveTo(0.5 * w, 0.5 * h - r);

    path.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    path.lineTo(-0.5 * w + r, 0.5 * h);

    path.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    path.lineTo(-0.5 * w, -0.5 * h + r);

    path.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    path.lineTo(0.5 * w - r, -0.5 * h);

    path.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    path.lineTo(0.5 * w, 0.5 * h - r);
    return path;
}

//========= GET MASK SHAPE =========//
const getMaskShape = () => {
    const shape = new THREE.Shape();
    const h = params.factor + 2 * params.maskOffset;
    const w = 1 + 2 * params.maskOffset;

    shape.moveTo(0.5 * w, 0.5 * h);
    shape.lineTo(-0.5 * w, 0.5 * h);
    shape.lineTo(-0.5 * w, -0.5 * h);
    shape.lineTo(0.5 * w, -0.5 * h);
    shape.lineTo(0.5 * w, 0.5 * h);

    shape.holes.push(getMaskHolePath());
    return shape
}
//========= GET MASK GEOMETRY =========//
export const getMaskGeometry = () => {
    return new THREE.ShapeGeometry(getMaskShape());
}

//========= GET BASE SHAPE =========//
const getBaseShape = () => {
    const r = params.screenRadius + params.screenBevel;
    const pi = Math.PI;

    const h = 1 * params.factor + 4 * params.screenOffset + 2 * params.screenBevel;
    const w = 1 + 2 * params.screenOffset + 2 * params.screenBevel;
    const shape = new THREE.Shape();

    shape.moveTo(0.5 * w, 0.5 * h - r);

    shape.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    shape.lineTo(-0.5 * w + r, 0.5 * h);

    shape.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    shape.lineTo(-0.5 * w, -0.5 * h + r);

    shape.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    shape.lineTo(0.5 * w - r, -0.5 * h);

    shape.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    shape.lineTo(0.5 * w, 0.5 * h - r);
    return shape;
}

//========= GET BASE GEOMETRY =========//
export const getBaseGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.screenDepth, // величина выдавливания
        bevelEnabled: true, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.baseBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.screenBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(getBaseShape(), extrudeSettings)
}

