import {Dialog, DialogContent, IconButton} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectDeleteId, selectDeleteModal, setDeleteModal} from "../../../redux-toolkit-store/appSlice";
import style from "./DeleteModal.module.scss";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {useDeletePortfolioMutation, useGetPortfoliosListQuery} from "../../../api/portfolio.api";

export const DeleteModal = () => {
    const dispatch = useAppDispatch();
    const deleteModal = useAppSelector(selectDeleteModal);
    const deleteId = useAppSelector(selectDeleteId);
    const onCloseHandler = () => dispatch(setDeleteModal(false));

    const {refetch: refetchList} = useGetPortfoliosListQuery();

    const [deletePortfolio, {isLoading}] = useDeletePortfolioMutation()

    const onDeleteHandler = async () => {
        try {
            await deletePortfolio({id: deleteId}).unwrap();
            refetchList();
            onCloseHandler();
        } catch (e: any) {
            console.log(e.message)
        }
    }

    return (
        <Dialog open={deleteModal}
                onClose={() => dispatch(setDeleteModal(false))}
                maxWidth='xs'
                sx={{
                    "& .MuiPaper-root": {
                        width: 500,
                        margin: "16px",
                        borderRadius: "10px"
                    }
                }}
        >
            <DialogTitle color='primary'
                         className={style.dialogTitle}
            >
                Удалить портфолио?
                <IconButton className={style.closeBtn}
                            onClick={onCloseHandler}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>

            <DialogContent sx={{
                padding: "20px 24px 20px!important"
            }}
                           className={style.dialogContent}
            >

                <Button variant="contained"
                        color="error"
                        startIcon={isLoading ? undefined : <DeleteForeverIcon/>}
                        fullWidth
                        onClick={onDeleteHandler}
                >
                    {isLoading ? "Удаление..." : "Удалить"}
                </Button>

                <Button variant="contained"
                        startIcon={<CloseIcon/>}
                        sx={{ marginLeft: "16px" }}
                        fullWidth
                        onClick={onCloseHandler}
                >
                    Отменить
                </Button>

            </DialogContent>

        </Dialog>
    )
}
