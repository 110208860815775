import React, {FC, memo} from "react";
import style from "./Portfolio.module.scss"
import {Container} from "../common/Container/Container";
import {H2} from "../common/H2/H2";
import {HashLink} from "react-router-hash-link";
import {translate} from "../../utils/lang";
import clsx from "clsx";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectLang, selectThemeType} from "../../redux-toolkit-store/appSlice";
import {useGetAllPortfoliosQuery} from "../../api/portfolio.api";

import src_id_0 from "../../assets/jpg/portfolio/min/id_0.jpg";
import src_id_1 from "../../assets/jpg/portfolio/min/id_1.jpg";
import src_id_2 from "../../assets/jpg/portfolio/min/id_2.jpg";
import src_id_3 from "../../assets/jpg/portfolio/min/id_3.jpg";
import src_id_5_wide from "../../assets/jpg/portfolio/min/id_5_wide.jpg";
import src_id_5_widest from "../../assets/jpg/portfolio/min/id_5_widest.jpg";
import src_id_5_column from "../../assets/jpg/portfolio/min/id_5_column.jpg";

interface IPortfolio {
    title: string
    withButton?: boolean
}


export const Portfolio: FC<IPortfolio> = ({
                                              title,
                                              withButton = true
                                          }) => {
    const lang = useAppSelector(selectLang);
    const themeType = useAppSelector(selectThemeType);

    return (
        <Container classNameInner={style.portfolio}>
            <H2 text={title}/>


            <div className={style.images}>

                <div className={style.mobile}>
                    {
                        [
                            {id: 2, src: src_id_2},
                            {id: 1, src: src_id_1},
                            {id: 3, src: src_id_3},
                            {id: 0, src: src_id_0},
                            {id: 5, src: src_id_5_wide},
                        ].map(({id, src}, key) => (
                            <HashLink key={key}
                                      to={`/portfolio/${id}/#header`}
                                      data-aos="zoom-in"
                                //data-aos-delay="300"
                            >
                                <img src={src} alt=""/>
                            </HashLink>
                        ))
                    }
                </div>

                <div className={style.middle}>

                    <div className={style.left}>
                        <div className={style.row}>
                            <HashLink to={`/portfolio/2/#header`}
                                      data-aos="zoom-in"
                                //data-aos-delay="300"
                            >
                                <img src={src_id_2} alt=""/>
                            </HashLink>
                            <HashLink to={`/portfolio/1/#header`}
                                      data-aos="zoom-in"
                            >
                                <img src={src_id_1} alt=""/>
                            </HashLink>
                        </div>

                        <div className={style.row}>
                            <HashLink to={`/portfolio/0/#header`}
                                      data-aos="zoom-in"
                                //data-aos-delay="100"
                            >
                                <img src={src_id_0} alt=""/>
                            </HashLink>
                            <HashLink to={`/portfolio/3/#header`}
                                      data-aos="zoom-in"
                                //data-aos-delay="200"
                            >
                                <img src={src_id_3} alt=""/>
                            </HashLink>
                        </div>

                        <HashLink to={`/portfolio/5/#header`}
                                  data-aos="zoom-in"
                            //data-aos-delay="200"
                        >
                            <img src={src_id_5_widest} alt=""/>
                        </HashLink>
                    </div>

                    <HashLink to={`/portfolio/5/#header`}
                              className={style.right}
                              data-aos="zoom-in"
                    >
                        <img src={src_id_5_column} alt=""/>
                    </HashLink>

                </div>
            </div>


            {
                withButton &&
                <HashLink to="/portfolio/#header"
                          className={clsx({
                              [style.btn]: true,
                              [style.btn_light]: themeType === "light",
                              [style.btn_dark]: themeType === "dark",
                          })}
                          data-aos="fade-up"
                >
                    <p>{translate("See more", lang)}</p>
                </HashLink>
            }
        </Container>
    )
}

export const PortfolioMemo = memo(Portfolio);
