import {HashLink} from "react-router-hash-link";
import React, {FC} from "react";
import style from "./ButtonLinkToTop.module.scss"
import clsx from "clsx";
import {svgIcons} from "../../../assets/svg/svgIcons";
import {useAppSelector} from "../../../redux-toolkit-store/hooks";
import {selectThemeType} from "../../../redux-toolkit-store/appSlice";
import {useLocation} from "react-router-dom";

interface IButtonLinkToTop {
    className?: string
}

export const ButtonLinkToTop: FC<IButtonLinkToTop> = ({className}) => {
    const themeType = useAppSelector(selectThemeType);
    const location = useLocation();
    const pathname = location.pathname;
    //console.log(pathname)
    const to = pathname + "#header";

    return (
        <HashLink to={to}
                  smooth={true}
                  className={clsx({
                      [style.buttonLinkToTop]: true,
                      [style.buttonLinkToTop_light]: themeType === "light",
                      [style.buttonLinkToTop_dark]: themeType === "dark",
                  }, Boolean(className) && className)}
        >
            {svgIcons.arrow_top}
        </HashLink>


    )
}
