import { Canvas } from "@react-three/fiber"
import {DepthOfField, EffectComposer} from "@react-three/postprocessing";
import {ThemeTypeEnum} from "../../../../redux-toolkit-store/appSlice";
import {FC} from "react";
import {Gamepad} from "./Gamepad";

const easing = (x: number) => Math.sqrt(1 - Math.pow(x - 1, 2));
const speed = 2;
const count = 40;
const depth = 80;

//========= GAMEPAD RAIN =========//
export const GamepadRain: FC<{themeType: ThemeTypeEnum}> = ({themeType}) => {
    return (
            <Canvas gl={{antialias: false}}
                    dpr={[1, 1.5]}
                    camera={{position: [0, 0, 10], fov: 20, near: 0.01, far: depth + 15}}>

                <color attach="background" args={[themeType === ThemeTypeEnum.light ? "#EFEEF3" : "#25303F"]}/>

                <ambientLight intensity={0.3} color="#FFF"/>
                <directionalLight position={[-40, 40, 40]} intensity={5}/>

                {
                    Array.from(
                        {length: count},
                        (_, i) => (
                            <Gamepad key={i}
                                     index={i}
                                     speed={speed}
                                     z={Math.round(easing(i / count) * depth)}
                            />
                        )
                    )
                }

                <EffectComposer multisampling={0}>
                    <DepthOfField target={[0, 0, 60]}
                                  focalLength={0.8} // 0.4
                                  bokehScale={10} // 7
                                  height={700}
                    />
                </EffectComposer>

            </Canvas>
    )
}
