import {Container} from "../common/Container/Container";
import React, {memo} from "react";
import style from "./howMuchIs.module.scss"
import {H2} from "../common/H2/H2";
import clsx from "clsx";
import {translate} from "../../utils/lang";
import {cards} from "./constants";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectLang, selectThemeType, setShowModal, setHowMuchIsCard} from "../../redux-toolkit-store/appSlice";
import {ButtonUI} from "../common/ButtonUI/ButtonUI";

export const HowMuchIs = () => {
    const themeType = useAppSelector(selectThemeType);
    const lang = useAppSelector(selectLang);
    const dispatch = useAppDispatch();

    return (
        <Container classNameInner={style.howMuchIs}>

            <H2 text="How much is"/>

            <div className={style.cards}>
                {
                    cards.map((card, index) => (
                        <div key={index}
                            //data-aos="fade-up"
                             className={clsx({
                                 [style.card]: true,
                                 [style.card_light]: themeType === "light",
                                 [style.card_dark]: themeType === "dark",
                             })}
                        >
                            <div className={style.iconWrapper}
                            >
                                <div className={style.back}
                                     style={{background: card.background}}
                                />
                                {card.icon}
                            </div>

                            <p className={style.title} data-aos="flip-up">
                                {translate(card.title, lang)}
                            </p>

                            <p className={style.text} data-aos="flip-up">
                                {translate(card.text, lang)}
                            </p>

                            <p className={style.price}
                               style={{color: card.background}}
                               data-aos="flip-up"
                            >
                                {`${translate("starting at", lang)} $${card.price}`}
                            </p>

                            <div data-aos="zoom-in">
                                <ButtonUI text={translate("Make an order", lang)}
                                          className={style.btn}
                                          onClick={() => {
                                              dispatch(setHowMuchIsCard(card))
                                              dispatch(setShowModal({show: true, type: 'how much is'}));
                                          }}
                                />
                            </div>


                            {/*<button className={style.btn}*/}
                            {/*        onClick={() => {*/}
                            {/*            dispatch(setHowMuchIsCard(card))*/}
                            {/*            dispatch(setShowModal({show: true, type: 'how much is'}));*/}
                            {/*        }}*/}
                            {/*        data-aos="zoom-in"*/}
                            {/*>*/}
                            {/*    {}*/}
                            {/*</button>*/}

                        </div>
                    ))
                }
            </div>

        </Container>
    )
}

export const HowMuchIsMemo = memo(HowMuchIs);
