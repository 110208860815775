import React, {FC, Suspense, useRef, useState} from "react";
import {Canvas, useFrame, useThree} from "@react-three/fiber";
import {Float, PerspectiveCamera, Plane, RoundedBox, Text3D, useTexture, OrbitControls} from "@react-three/drei";
import * as THREE from "three";
import {Euler, Group} from "three";
import {easing} from 'maath';
import {animated, useSpring} from "@react-spring/three";
import fontUrl from "../../../../assets/fonts/Montserrat_Bold.json";

const params = {
    screenRadius: 0.015,
    factor: 16 / 9,
    screenBaseOffset: 0.015,
    screenBaseHeight: 0.1,
    screenBaseBevel: 0.005,
}

interface IMonitorModel {
    src: string
    isLeft: boolean
}

//========= MONITOR MODEL =========//
export const MonitorModel: FC<IMonitorModel> = ({src, isLeft}) => {
    const [canvasHover, setCanvasHover] = useState(false);


    return (
        <Canvas
            gl={{antialias: true}}
            onPointerOver={() => setCanvasHover(true)}
            onPointerOut={() => setCanvasHover(false)}
        >
            <ambientLight intensity={1.0}/>
            <directionalLight position={[2, 0, 2]} intensity={1.0}/>
            <directionalLight position={[-2, 0, 2]} intensity={1.0}/>

            <Suspense fallback={null}>
                <Model canvasHover={canvasHover} src={src} isLeft={isLeft}/>
            </Suspense>

            <PerspectiveCamera makeDefault
                               position={[isLeft ? -1 : 1, 0.2, 2]}
                               zoom={1.15}
            />

            <OrbitControls enableRotate={false}
                           enableZoom={false}
            />
        </Canvas>
    )
}

interface IModel {
    canvasHover: boolean
    src: string,
    isLeft: boolean
}

//========= MODEL =========//
const Model: FC<IModel> = ({
                               canvasHover,
                               src,
                               isLeft
                           }) => {
    const delta = 0.015;

    const [map] = useTexture([src])
    map.repeat = new THREE.Vector2(1, 1);

    const ref = useRef<Group>(null!);
    useFrame((state, delta) => {
        easing.damp3(
            // @ts-ignore
            ref.current.rotation,
            [
                canvasHover ? -state.pointer.y / 10 : 0,
                canvasHover ? state.pointer.x / 10 : 0,
                0
            ],
            0.2,
            delta
        );
    })

    const {color, textColor} = useSpring({
        color: canvasHover ? "#555" : "#222",
        textColor: !canvasHover ? "#555" : "#222",
    })
    // const color = canvasHover ? "#555" : "#151515"
    // const textColor = !canvasHover ? "#555" : "#151515";

    return (
        <Float speed={4}
               floatingRange={[-0.05, 0.05]}
               rotationIntensity={0.25}
        >
            <group ref={ref}
                   position={[
                       isLeft ? 0.15 : -0.15,
                       0.16,
                       0
                   ]}
            >
                {/*экран*/}
                <Plane args={[params.factor, 1]}
                       position={[0, 0, params.screenBaseHeight + 0.001]}
                >
                    <meshStandardMaterial map={map}
                                          metalness={0.75}
                                          emissiveIntensity={0.5}
                    />
                </Plane>

                {/*корпус с скруглёнными углами*/}
                <mesh geometry={getScreenBaseGeometry()}
                      position={[0, -1.5 * delta, 0]}
                >
                    {/*@ts-ignore*/}
                    <animated.meshStandardMaterial color={color}
                                                   emissive={color}
                                                   roughness={0.4}
                                                   metalness={1}
                    />
                </mesh>


                {/*стойка*/}
                <mesh position={[0, -0.6, -0.15]}
                      rotation={new Euler(0.04 * Math.PI, 0, 0, "YXZ")}
                >
                    <cylinderGeometry args={[0.05, 0.05, 0.4, 32]}/>
                    {/*@ts-ignore*/}
                    <animated.meshStandardMaterial color={color}
                                                   emissive={color}
                                                   roughness={0.4}
                                                   metalness={0.75}
                    />
                </mesh>

                {/*подставка*/}
                <RoundedBox args={[0.8, 0.05, 0.6]}
                            radius={0.025} // Radius of the rounded corners. Default is 0.05
                            smoothness={4} // The number of curve segments. Default is 4
                            steps={2} // The number of bevel segments. Default is 4, setting it to 0 removes the bevel, as a result the texture is applied to the whole geometry.
                            creaseAngle={0.4} // Smooth normals everywhere except faces that meet at an angle greater than the crease angle
                            position={[0, -0.8, 0]}
                >
                    {/*@ts-ignore*/}
                    <animated.meshStandardMaterial color={color}
                                                   emissive={color}
                                                   roughness={0.4}
                                                   metalness={0.75}
                    />
                </RoundedBox>

                <Text3D
                    // @ts-ignore
                    font={fontUrl}
                    size={0.025}
                    height={0.01}
                    position={[-0.14, -0.54, params.screenBaseHeight + 0.001]}

                >
                    DIGIT NOVA
                    {/*@ts-ignore*/}
                    <animated.meshStandardMaterial color={textColor}/>
                </Text3D>

            </group>

        </Float>

    )
}

//========= GET SCREE BASE SHAPE =========//
const getScreenBaseShape = () => {
    const r = params.screenRadius;
    const pi = Math.PI;

    const h = 1 + 5 * params.screenBaseOffset;
    const w = 1 * params.factor + 2 * params.screenBaseOffset;
    const shape = new THREE.Shape();

    shape.moveTo(0.5 * w, 0.5 * h - r);

    shape.absarc(0.5 * w - r, 0.5 * h - r, r, 0, pi / 2, false);
    shape.lineTo(-0.5 * w + r, 0.5 * h);

    shape.absarc(-0.5 * w + r, 0.5 * h - r, r, pi / 2, pi, false);
    shape.lineTo(-0.5 * w, -0.5 * h + r);

    shape.absarc(-0.5 * w + r, -0.5 * h + r, r, 1 * pi, 1.5 * pi, false);
    shape.lineTo(0.5 * w - r, -0.5 * h);

    shape.absarc(0.5 * w - r, -0.5 * h + r, r, 1.5 * pi, 2 * pi, false);
    shape.lineTo(0.5 * w, 0.5 * h - r);
    return shape;
}

//========= GET SCREE BASE GEOMETRY =========//
const getScreenBaseGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.screenBaseHeight - 2 * params.screenBaseBevel, // величина выдавливания
        bevelEnabled: true, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.screenBaseBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.screenBaseBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(getScreenBaseShape(), extrudeSettings)
}


