import design_ai from "../../../assets/png/offer/card-icons/0.png";
import design_ps from "../../../assets/png/offer/card-icons/1.png";
import design_figma from "../../../assets/png/offer/card-icons/2.png";
import design_uiux from "../../../assets/png/offer/card-icons/3.png";

import frontend_react from "../../../assets/png/offer/card-icons/4.png";
import frontend_redux from "../../../assets/png/offer/card-icons/frontend_redux.png";
import frontend_nextjs from "../../../assets/png/offer/card-icons/frontend_nextjs.png";
import frontend_material_ui from "../../../assets/png/offer/card-icons/frontend_materialui.png";
import frontend_sass from "../../../assets/png/offer/card-icons/frontend_sass.png";
import frontend_formik from "../../../assets/png/offer/card-icons/frontend_formik.png";
import frontend_ts from "../../../assets/png/offer/card-icons/frontend_typescript.png";
import frontend_saga from "../../../assets/png/offer/card-icons/frontend_saga.png";
import frontend_router from "../../../assets/png/offer/card-icons/frontend_react-router.png";
import frontend_axios from "../../../assets/png/offer/card-icons/frontend_axios.png";

import backend_nodejs from "../../../assets/png/offer/card-icons/5.png";

import crypto_ethereum from "../../../assets/png/offer/card-icons/10.png";

export const portfolioIcons = {
    // design
    design_ai,
    design_ps,
    design_figma,
    design_uiux,

    // frontend
    frontend_react,
    frontend_redux,
    frontend_nextjs,
    frontend_material_ui,
    frontend_sass,
    frontend_formik,
    frontend_ts,
    frontend_saga,
    frontend_router,
    frontend_axios,

    // backend
    backend_nodejs,

    // crypto
    crypto_ethereum

}