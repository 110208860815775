import {FC, useRef, useState} from "react";
import {useFrame, useThree} from "@react-three/fiber";
import * as THREE from "three";
import {Cylinder} from "@react-three/drei";

const params = {
    baseRadius: 1,
    baseDepth: 0.3,
    baseBevel: 0.1,
    baseCenterOffset: 2.5,
    baseColor: "#999",
    buttonRadius: 0.25,
    buttonDepth: 0.125,
    buttonColor: "#333",
    buttonOffset: 0.5,
    crossRadius: 0.2,
    crossOffset: 0.5,
}

interface IEther {
    index: number
    z: number
    speed: number
}

//========= GAMEPAD =========//
export const Gamepad: FC<IEther> = ({index, z, speed}) => {
    const ref = useRef<THREE.Group>(null!)
    const {viewport, camera} = useThree();
    const {width, height} = viewport.getCurrentViewport(camera, [0, 0, -z]);

    const [data] = useState({
        // Randomly distributing the objects along the vertical
        y: THREE.MathUtils.randFloatSpread(height * 2),
        // This gives us a random value between -1 and 1, we will multiply it with the viewport width
        x: THREE.MathUtils.randFloatSpread(2),
        // How fast objects spin, randFlost gives us a value between min and max, in this case 8 and 12
        spin: THREE.MathUtils.randFloat(8, 12),
        // Some random rotations, Math.PI represents 360 degrees in radian
        rX: Math.random() * Math.PI,
        rZ: Math.random() * Math.PI
    });

    useFrame((state, dt) => {
        // Make the X position responsive, slowly scroll objects up at the Y, distribute it along the Z
        // dt is the delta, the time between this frame and the previous, we can use it to be independent of the screens refresh rate
        // We cap dt at 0.1 because now it can't accumulate while the user changes the tab, it will simply stop
        if (dt < 0.1) {
            ref.current.position.set(
                (index === 0 ? 0 : data.x * width),
                (data.y -= dt * speed),
                -z
            );
        }
        // Rotate the object around
        ref.current.rotation.set((data.rX += dt / data.spin), Math.sin(index * 1000 + state.clock.elapsedTime / 10) * Math.PI, (data.rZ += dt / data.spin));
        // If they're too far up, set them back to the bottom
        if (data.y < -height * (index === 0 ? 4 : 1)) {
            data.y = +(height * (index === 0 ? 4 : 1))
        }
    })

    return (
        <group ref={ref} scale={1.0}>

            {/*base*/}
            <mesh geometry={baseGeometry()}
                  position={[0, 0, params.baseBevel]}
            >

                <meshStandardMaterial color={params.baseColor}
                                      emissive={params.baseColor}
                                      roughness={0.4}
                                      metalness={1}
                />
            </mesh>

            {/*cross*/}
            <mesh geometry={crossGeometry()}
                  position={[-0.5 * params.baseCenterOffset, 0, params.baseDepth + 2 * params.baseBevel]}
            >

                <meshStandardMaterial color={params.buttonColor}
                                      emissive={params.buttonColor}
                                      roughness={0.4}
                                      metalness={1}
                />
            </mesh>

            {/*buttons*/}
            <group position={[0.5 * params.baseCenterOffset, params.buttonOffset, 0]}>
                <GameButton/>
            </group>
            <group position={[0.5 * params.baseCenterOffset, -params.buttonOffset, 0]}>
                <GameButton/>
            </group>
            <group position={[0.5 * params.baseCenterOffset - params.buttonOffset, 0, 0]}>
                <GameButton/>
            </group>
            <group position={[0.5 * params.baseCenterOffset + params.buttonOffset, 0, 0]}>
                <GameButton/>
            </group>


        </group>
    )
}

//========= BASE SHAPE =========//
const baseShape = () => {
    const shape = new THREE.Shape();
    //shape.arcLengthDivisions = 600;
    const r = params.baseRadius;
    const l = params.baseCenterOffset;
    const pi = Math.PI;

    shape.absarc(0.5 * l, 0, r, -0.75 * pi, 0.5 * pi, false);
    shape.lineTo(-0.5 * l, r);
    shape.absarc(-0.5 * l, 0, r, 0.5 * pi, 1.75 * pi, false);

    return shape
}

//========= BASE GEOMETRY =========//
const baseGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.baseDepth, // величина выдавливания
        bevelEnabled: true, // наличие фаски
        // При наличии фаски новый материал выступает за изначальную поверхность модели
        // как в направлении выдавливания, так и в плоскости формы, т.е. фаска образуется за счет добавления объема
        bevelThickness: params.baseBevel, // выступ фаски в направлении выдавливания
        bevelSize: params.baseBevel, // выступ фаски в плоскости формы
        bevelOffset: 0, // смещает всю поверхность модели по нормали на указанную феличину
        bevelSegments: 4, // число сегментов фаски (чем больше сегментов тем более скруглённая фаска)
    };
    return new THREE.ExtrudeGeometry(baseShape(), extrudeSettings)
}

//========= GAME BUTTON =========//
const GameButton = () => {
    return (

        <Cylinder args={[params.buttonRadius, params.buttonRadius, params.buttonDepth, 32]}
                  rotation-x={0.5 * Math.PI}
                  position={[0, 0, params.baseDepth + 2 * params.baseBevel + 0.5 * params.buttonDepth]}

        >
            <meshStandardMaterial color={params.buttonColor}
                                  emissive={params.buttonColor}
                                  roughness={0.4}
                                  metalness={1}
            />
        </Cylinder>


    )
}

//========= CROSS SHAPE =========//
const crossShape = () => {
    const shape = new THREE.Shape();
    const r = params.crossRadius;
    const l = params.crossOffset;
    const pi = Math.PI;

    shape.moveTo(r, r);
    shape.lineTo(r, l);
    shape.absarc(0, l, r, 0, pi, false);
    shape.lineTo(-r, r);
    shape.lineTo(-l, r);
    shape.absarc(-l, 0, r, 0.5 * pi, 1.5 * pi, false);
    shape.lineTo(-r, -r);
    shape.lineTo(-r, -l);
    shape.absarc(0, -l, r, pi, 2 * pi, false);
    shape.lineTo(r, -r);
    shape.lineTo(l, -r);
    shape.absarc(l, 0, r, -0.5 * pi, 0.5 * pi, false);

    return shape
}

//========= CROSS GEOMETRY =========//
const crossGeometry = () => {
    const extrudeSettings = {
        steps: 1, // количество сегментов в направлении выдавливания
        depth: params.buttonDepth, // величина выдавливания
        bevelEnabled: false, // наличие фаски
    };
    return new THREE.ExtrudeGeometry(crossShape(), extrudeSettings)
}
