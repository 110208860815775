import Typography from "@mui/material/Typography/Typography";
import React, {FC, useEffect, useState} from "react";
import {
    useCreatePortfolioMutation,
    useGetPortfoliosListQuery,
} from "../../api/portfolio.api";
import {Container} from "../common/Container/Container";
import style from "./AdminPage.module.scss";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import CloseIcon from '@mui/icons-material/Close';
import {PortfolioListItem} from "./PortfolioListItem/PortfolioListItem";
import {IPortfolioFormValues, IPortfolioItem} from "../../types/portfolio.types";
import {replaceItemsInList} from "../../utils/replaceItemsInList";
import {Button} from "@mui/material";
import {ItemForm} from "./ItemForm/ItemForm";
import CircularProgress from "@mui/material/CircularProgress";
import {DeleteModal} from "./DeleteModal/DeleteModal";
import {getLocalStorage, removeLocalStorage} from "../../localStorage/localStorage";
import { useNavigate } from "react-router-dom";
import {HashLink} from "react-router-hash-link";

export const AdminPage = () => {
    const navigate = useNavigate();
    const token = getLocalStorage();
    if (!token) {
        navigate("/")
    }

    const {data, isFetching, refetch: refetchList} = useGetPortfoliosListQuery();

    const [showAddForm, setShowAddForm] = useState(false);
    const onFormClickHandler = () => setShowAddForm(!showAddForm);
    const [createPortfolio, resultCreate] = useCreatePortfolioMutation();

    const onLogoutHandler = () => {
        removeLocalStorage();
    }

    return (
        <div className={style.adminPage}>

            <DeleteModal/>

            <Container classNameInner={style.inner}>
                <div className={style.top}>
                    <div className={style.titleWrapper}>
                        <Typography variant="h4">Список портфолио</Typography>
                        {
                            isFetching &&
                            <div className={style.preloader}>
                                <CircularProgress/>
                            </div>
                        }
                    </div>


                    <Button variant="contained"
                            color={showAddForm ? "error" : "success"}
                            startIcon={showAddForm ? <CloseIcon/> : <AddCircleOutlineIcon/>}
                            onClick={onFormClickHandler}
                    >
                        {showAddForm ? "Закрыть форму" : "Добавить портфолио"}
                    </Button>
                </div>

                {
                    showAddForm && (
                        <ItemForm label="Создать портфолио"
                                  initialValues={{
                                      name: "Новое портфолио",
                                      url: "#",
                                      descriptionEng: "Enter description",
                                      descriptionRus: "Введите описание",
                                      enable: true,
                                      category: [],
                                      icons: [],
                                      imgsMain: "",
                                      imgsDesktop: "",
                                      imgsMobile: "",
                                  }}
                                  onSubmitHandler={async (portfolioUpdateData: IPortfolioFormValues) => {
                                      try {
                                          const response = await createPortfolio({portfolioUpdateData}).unwrap();
                                          refetchList();
                                          setShowAddForm(false);
                                      } catch (e: any) {
                                          console.log(e.message)
                                      }

                                  }}
                        />
                    )
                }


                {
                    data && (
                        <ListItems items={data}/>
                    )
                }

                <div className={style.bottom}>
                    <HashLink to="/#header"
                              smooth={true}
                              className={style.logout}
                    >
                        <Button color="error"
                                variant="contained"
                                startIcon={<LogoutIcon/>}
                                onClick={onLogoutHandler}

                        >
                            Выйти из профиля
                        </Button>
                    </HashLink>
                </div>



            </Container>
        </div>
    )
}

//========= LIST ITEMS =========//
const ListItems: FC<{ items: IPortfolioItem[] }> = ({items}) => {
    // items - из бэкенда
    // list - локальное значение, меняется при перетаскивании
    // при отпускании карточки - list = {id, order} отправляется на сервер и переписывает items
    const [list, setList] = useState(items);

    useEffect(() => {
        setList(items)
    }, [items])

    const replaceItems = (id1: string, id2: string) => setList(replaceItemsInList(id1, id2, list))

    return (
        <div className={style.list}>
            {
                [...list]
                    .sort((itemA, itemB) => {
                        if (itemA.order > itemB.order) {
                            return 1
                        }
                        if (itemA.order < itemB.order) {
                            return -1
                        }
                        return 0
                    })
                    .map(item => (
                        <PortfolioListItem key={item.id}
                                           item={item}
                                           replaceItems={replaceItems}
                                           items={items}
                                           list={list}
                        />
                    ))
            }
        </div>
    )
}
