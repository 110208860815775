import React, {memo} from "react";
import {Container} from "../common/Container/Container";
import {H2} from "../common/H2/H2";
import style from "./aboutUs.module.scss"
import {translate} from "../../utils/lang";
// import img from "../../assets/png/about us/img.png";
// import blur from "../../assets/png/about us/blur.png";
import {svgIcons} from "../../assets/svg/svgIcons";
import clsx from "clsx";
import {items, itemsTop} from "./constants";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectLang, selectThemeType} from "../../redux-toolkit-store/appSlice";
import {AnimatedNumber} from "../common/AnimatedNumber/AnimatedNumber";
import {AnimatedIcon1, animatedIcons} from "./AnimatedIcons/AnimatedIcons";


export const AboutUs = () => {
    const lang = useAppSelector(selectLang);
    const themeType = useAppSelector(selectThemeType);

    return (
        <Container id="about"
                   classNameInner={style.aboutUs}
        >
            <H2 text="About Us"/>

            {/*<div className={style.cardWrapper}>*/}

            {/*    <div className={style.imgWrapper} data-aos="fade-left">*/}
            {/*        <img src={img} alt=""/>*/}
            {/*        <img src={blur} alt=""/>*/}
            {/*    </div>*/}

            {/*    <div className={style.card} data-aos="fade-right">*/}

            {/*        <p className={clsx(style.text, style.text1)}>*/}
            {/*            {translate(text1, lang)}*/}
            {/*        </p>*/}
            {/*        <p className={clsx(style.text, style.text1)}>*/}
            {/*            {``}*/}
            {/*        </p>*/}
            {/*        <p className={style.text}>*/}
            {/*            {translate(text2, lang)}*/}
            {/*        </p>*/}

            {/*    </div>*/}
            {/*</div>*/}

            <div className={style.itemsTop}>
                {
                    itemsTop.map(({value, label, color}, key) => (
                        <div key={key}
                             className={style.item}
                        >
                            <div style={{color}}
                                 className={style.valueWrapper}
                            >
                                <AnimatedNumber value={value}
                                                className={style.value}
                                                step={1}
                                                time={1000}
                                />
                                {
                                    key !== 0 && (
                                        <p className={style.value}>+</p>
                                    )
                                }
                            </div>

                            <div className={style.divider}
                                 style={{backgroundColor: color}}
                            />

                            <p className={style.label}>
                                {label}
                            </p>
                        </div>
                    ))
                }
            </div>

            <div className={style.items}>
                {
                    items.map(({title, text}, key) => (
                        <div key={key}
                             className={style.item}
                             data-aos="fade-up"
                        >
                            <div className={clsx({
                                [style.iconWrapper]: true,
                                [style.iconWrapper_light]: themeType === "light",
                                [style.iconWrapper_dark]: themeType === "dark",
                            })}>
                                {/*@ts-ignore*/}
                                {/*{svgIcons[`aboutUs${index}`]}*/}
                                {animatedIcons[key]}
                            </div>
                            <p className={style.title} data-aos="fade-up">{translate(title, lang)}</p>
                            <p className={style.text} data-aos="fade-up">{translate(text, lang)}</p>
                        </div>
                    ))
                }
            </div>

        </Container>
    )
}

export const AboutUsMemo = memo(AboutUs)
