import React, {memo, useEffect} from "react";
import style from "./WorkPage.module.scss"
import img from "../../assets/png/work/0.png";
import {BigForm} from "../common/BigForm/BigForm";
import {WorkMemo} from "./Work/Work";
import wave_light from "../../assets/png/wave_light-min.png";
import wave_dark from "../../assets/png/wave_dark-min.png";
import {PortfolioMemo} from "../C5_Portfolio/Portfolio";
import {useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {
    selectThemeType,
    selectThemeBefore,
    selectThemeTypeBefore,
    ThemeTypeEnum
} from "../../redux-toolkit-store/appSlice";
import {useGetPortfolioByIdQuery, useIncrementCountMutation} from "../../api/portfolio.api";
import {skipToken} from "@reduxjs/toolkit/query";
import {PortfolioCategoryType} from "../../types/portfolio.types";
import {useMediaQuery} from "@mui/material";
import {InstancesEtherRain} from "../common/Three/InstancesEtherRain/InstancesEtherRain";
import {InstancesWebRain} from "../common/Three/InstancesWebRain/InstancesWebRain";
import {StarsScroll, StarsScrollDark, StarsScrollLight} from "../common/Three/Stars/Stars";
import {GamepadRain} from "../common/Three/GamepadRain/GamepadRain";
import clsx from "clsx";

export const WorkPage = () => {
    const {id} = useParams<{ id: string }>();
    const isDesktop = useMediaQuery('(min-width:1200px)');
    const themeType = useAppSelector(selectThemeType);

    const dispatch = useAppDispatch();

    const wave = themeType === ThemeTypeEnum.light ? wave_light : wave_dark;

    const {data, isFetching} = useGetPortfolioByIdQuery(id ?? skipToken);
    const [incrementCount, {isLoading}] = useIncrementCountMutation();

    const isStars = id === "f79d88ab-836d-4e2f-a5c6-5c663dca1bdb"
        || id === "3";

    const isWeb = data
        && data.category.includes("Frontend" as PortfolioCategoryType)
        && !data.category.includes("Games" as PortfolioCategoryType)
        && !data.category.includes("Blockchain" as PortfolioCategoryType)
        && !isStars;

    const isBlockchain = data
        && data.category.includes("Blockchain" as PortfolioCategoryType)
        && !isStars;

    const isGames = data
        && data.category.includes("Games" as PortfolioCategoryType)
        && !isStars;

    return (
        <div className={style.workPage}>
            <div className={style.images}>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
                <img src={wave} alt="" className={clsx(style.wave, "wave")}/>
            </div>

            <div className={style.threeWrapper}>
                {isWeb && <InstancesWebRain themeType={themeType}/>}
                {isBlockchain && <InstancesEtherRain themeType={themeType}/>}

                {
                    isStars && (
                        themeType === ThemeTypeEnum.dark ? <StarsScrollLight/> : <StarsScrollDark/>
                    )
                }
                {isGames && <GamepadRain themeType={themeType}/>}

                <StarsScroll themeType={themeType}/>
            </div>


            <div className={style.content}>
                <WorkMemo/>
                <PortfolioMemo title="Other jobs"
                               withButton={false}
                />
                <div id="contacts" data-aos="fade-up">
                    <BigForm title="Do you want to order website development?"
                             className={style.bigForm}
                    />
                </div>

            </div>

        </div>
    )
}

export const WorkPageMemo = memo(WorkPage)
