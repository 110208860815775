import React, {useEffect, useLayoutEffect, useRef} from "react";
import style from "./Preloader.module.scss";
import gsap from "gsap";
import clsx from "clsx";
import {useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectPreloader} from "../../redux-toolkit-store/appSlice";

export const Preloader = () => {

    const preloader = useAppSelector(selectPreloader);
    const appRef = useRef<HTMLDivElement>(null!);
    const timelineRef = useRef<gsap.core.Timeline>(null!);

    useLayoutEffect(() => {
        const ctx = gsap.context((self) => {

            gsap.timeline({repeat: -1})
                .set(".circle", {
                    width: 20,
                    height: 20,
                    opacity: 1,
                    boxShadow: "0 0 0 #FFF, inset 0 0 0 #FFF",
                })
                .to(".circle", {
                    duration: 1,
                    width: 80,
                    height: 80,
                    opacity: 0,
                    boxShadow: "0 0 15px #FFF, inset 0 0 15px #FFF"
                })

        }, appRef);
        return () => ctx.revert();
    }, [preloader]);

    return (
        <div className={style.preloader}
             ref={appRef}
        >
            <div className={clsx(style.circle, "circle")}/>
        </div>
    )
}
