import * as THREE from "three";
import {ThemeTypeEnum} from "../../../../redux-toolkit-store/appSlice";

const vertexShader = `
    attribute float scale;
    void main() {
        vec4 mvPosition = modelViewMatrix * vec4( position, 1.0 );
        gl_PointSize = scale * ( 600.0 / - mvPosition.z );
        gl_Position = projectionMatrix * mvPosition;
    }
`

// gl_PointSize = scale * ( 300.0 / - mvPosition.z );//
// gl_PointSize = scale * ( 3.0 );

const fragmentShader =
    `
    uniform vec3 color;
    void main() {
            if ( length( gl_PointCoord - vec2( 0.5, 0.5 ) ) > 0.475 ) discard;
            gl_FragColor = vec4( color, 1.0 );
    }
`

export const pointShaderMaterial = ( themeType: ThemeTypeEnum ) => ({
    uniforms: {
        color: {
            value: themeType === ThemeTypeEnum.dark ? new THREE.Color(0xFFFFFF) : new THREE.Color(0x000000)
        } ,
        size: {value: 10},
    },
    vertexShader,
    fragmentShader,
    transparent: true
})

export const pointShaderMaterialLight = () => ({
    uniforms: {
        color: {
            value: new THREE.Color(0xFFFFFF)
        } ,
        size: {value: 10},
    },
    vertexShader,
    fragmentShader,
    transparent: true
})

export const pointShaderMaterialDark = () => ({
    uniforms: {
        color: {
            value: new THREE.Color(0x000000)
        } ,
        size: {value: 10},
    },
    vertexShader,
    fragmentShader,
    transparent: true
})
