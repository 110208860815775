export const text1 = "The Digital Design Studio is a digital solutions studio designed to enable you to bring your business into the modern Internet space. We offer not only up-to-date but also ahead of time solutions: from “turnkey websites” to NFT products.";
export const text2 = "We use the most modern technologies and relevant approaches. So your website, mobile app, game, or ad campaign will look and work exactly the way it should look and work today.";

export const itemsTop = [
    {
        value: 20,
        label: "people in the team",
        color: "#0085FF",
    },
    {
        value: 100,
        label: "projects launched",
        color: "#E744F6",
    },
    {
        value: 10,
        label: "years of work experience",
        color: "#00FFAF",
    },
]


export const items = [
    {
        title: "We create an idea for your future product",
        text: "We develop the interface, logic of behavior, and work. We agree on the technologies used and the terms of creation.",
    },
    {
        title: "We develop the technical part",
        text: "We write code, test, create a design, test again, hone the logic of work, check. The product must work perfectly and look stylish.",
    },
    {
        title: "Planning a promotion strategy",
        text: "Our task is not only to release a perfectly working product for you but also to help make it popular. Therefore, we will create a marketing strategy.",
    },
    {
        title: "We launch your project",
        text: "After all checks, we launch your project within the specified time frame. And we start post-release support.",
    },
];
