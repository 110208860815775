import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "./store";
import {ILoginData} from "../types/auth.type";
import {authAPI} from "../api/auth.api";
import {useNavigate} from "react-router-dom";

//==================== INITIAL STATE ===================//
const initialState = {
    loading: false,
    showLoginForm: false
}
type InitialStateType = typeof initialState

//==================== SLICE ===================//
export const authSlice = createSlice({
    name: "auth",
    initialState: initialState as InitialStateType,
    reducers: {
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload
        },
        setShowLoginForm: (state, action: PayloadAction<boolean>) => {
            state.showLoginForm = action.payload
        },


    },
})

//==================== ACTION CREATORS ===================//
export const {
    setLoading,
    setShowLoginForm
} = authSlice.actions;

//=================== ASYNC THUNK ==================//

//=================== SELECTORS ==================//
export const selectLoading = (state: RootState) => state.auth.loading;
export const selectShowLoginForm = (state: RootState) => state.auth.showLoginForm;

export const authReducer = authSlice.reducer
