import React, {FC} from "react";
import style from "./BurgerMenu.module.scss"
import clsx from "clsx";
import {translate} from "../../../utils/lang";
import {HashLink} from "react-router-hash-link";
import {links} from "../../common/Links/Links";
import {useLocation} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../../../redux-toolkit-store/hooks";
import {
    selectBurgerMenu,
    selectLang,
    selectTheme,
    selectThemeType,
    setBurgerMenu,
    setCategory
} from "../../../redux-toolkit-store/appSlice";

export const BurgerMenu = () => {
    const themeType = useAppSelector(selectThemeType);
    const lang = useAppSelector(selectLang);
    const theme = useAppSelector(selectTheme);
    const burgerMenu = useAppSelector(selectBurgerMenu);
    const dispatch = useAppDispatch();
    const {pathname, hash} = useLocation();

    //links[3].to = pathname + "/#contacts";
    links[2].to = pathname + "/#contacts";

    // let linkIndex = 0;
    //
    // if (hash === '#contacts') {
    //     linkIndex = 3
    // } else if (pathname === "/shop") {
    //     linkIndex = 2
    // } else if (pathname === '/portfolio') {
    //
    // }

    return (
        <div className={clsx({
            [style.burgerMenu]: true,
            [style.burgerMenu_light1]: theme === "light1",
            [style.burgerMenu_light2]: theme === "light2",
            [style.burgerMenu_dark1]: theme === "dark1",
            [style.burgerMenu_dark2]: theme === "dark2",
            [style.burgerMenu_show]: burgerMenu,
        })}>
            <div className={style.inner}>
                {
                    links.map(({to, label}, index) => (
                        <HashLink key={index}
                                  to={to}
                                  className={clsx({
                                      [style.link]: true,
                                      [style.link_light]: themeType === "light",
                                      [style.link_dark]: themeType === "dark",
                                  })}
                                  smooth={true}
                                  onClick={() => {
                                      dispatch(setBurgerMenu(false));
                                      dispatch(setCategory("All"));
                                  }}
                        >
                            {
                                translate(label, lang)
                            }
                        </HashLink>
                    ))
                }
            </div>
        </div>
    )
}
