import {Dialog, IconButton, DialogContent} from "@mui/material";
import React from "react";
import {useAppDispatch, useAppSelector} from "../../redux-toolkit-store/hooks";
import {selectShowLoginForm, setShowLoginForm} from "../../redux-toolkit-store/authSlice";
import CloseIcon from '@mui/icons-material/Close';
import style from "./LoginForm.module.scss";
import DialogTitle from "@mui/material/DialogTitle";
import {ILoginData} from "../../types/auth.type";
import {Form, Formik, FormikErrors, FormikHelpers, FormikProps} from "formik";
import LockIcon from '@mui/icons-material/Lock';
import {CustomPasswordField} from "../common/CustomPasswordField/CustomPasswordField";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {authAPI} from "../../api/auth.api";
import {setLocalStorage} from "../../localStorage/localStorage";

export const LoginForm = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const showLoginForm = useAppSelector(selectShowLoginForm);

    const onCloseHandler = () => dispatch(setShowLoginForm(false))

    const initialValues: ILoginData = {
        password: ''
    };

    const validate = ({password}: ILoginData): FormikErrors<ILoginData> => {
        const errors: FormikErrors<ILoginData> = {};
        if (!password) {
            errors.password = "Required"
        }
        return errors
    }

    const onSubmitHandler = async (
        values: ILoginData,
        formikHelpers: FormikHelpers<ILoginData>
    ) => {
        try {
            const response = await authAPI.login(values);
            //console.log(response)
            const accessToken = response.data.accessToken;
            setLocalStorage({accessToken});
            dispatch(setShowLoginForm(false))
            navigate("/admin/#header");
        } catch (e: any) {
            console.log(e.message)
            if (e.response.data.message) {
                formikHelpers.setFieldError("password", e.response.data.message)
            }
            // setAlert({
            //     open: true,
            //     message: e.response?.data?.message || e.message,
            //     severity: "error"
            // })
        } finally {
            //setLoading(false);
            formikHelpers.setSubmitting(false);
        }
    };

    return (
        <Dialog open={showLoginForm}
                onClose={onCloseHandler}
                maxWidth='xs'
                sx={{
                    "& .MuiPaper-root": {
                        width: 500,
                        margin: "16px",
                        borderRadius: "10px"
                    }
                }}
        >
            <DialogTitle color='primary'
                         className={style.dialogTitle}
            >
                Enter password
                <IconButton className={style.closeBtn}
                            onClick={onCloseHandler}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{
                padding: "20px 24px 20px!important"
            }}>
                <Formik
                    initialValues={initialValues}
                    validate={validate}
                    onSubmit={onSubmitHandler}
                >
                    {
                        (props: FormikProps<ILoginData>) => (
                            <Form>
                                <CustomPasswordField name="password"
                                                     color='primary'
                                                     fullWidth
                                                     label="Password"
                                                     placeholder="Enter your password"
                                                     variant="outlined"
                                                     size="small"
                                                     icon={<LockIcon/>}
                                />
                                <Button type="submit"
                                        disabled={props.isSubmitting}
                                        color="primary"
                                        fullWidth
                                        variant="contained"
                                        className={style.submitBtn}
                                >
                                    Login
                                </Button>
                            </Form>
                        )
                    }
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
